import { useContext } from 'react';
import { FirebaseContext, FirebaseDataContext } from '../../../firebase';

import { LoadingGrid } from '../utils';

import { Link } from 'react-router-dom';

// import { evaluationQuestions } from '../reviewerSpec';
import { ReviewerInstructions } from './instructions';
import * as routes from '../../../routing/routes';

/* Layout */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import LoopIcon from '@material-ui/icons/Loop';
const colorStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.secondary.main,
  },
  blue: {
    color: theme.palette.primary.main,
  },
  orange: {
    color: theme.palette.warning.main,
  },
  red: {
    color: theme.palette.error.main,
  },
  bckgGreen: {
    backgroundColor: theme.palette.secondary.main,
  },
  bckgBlue: {
    backgroundColor: theme.palette.primary.main,
  },
  bckgOrange: {
    backgroundColor: theme.palette.warning.main,
  },
  bckgRed: {
    backgroundColor: theme.palette.error.main,
  },
}));

const reviewerListStyles = makeStyles((theme) => ({
  paper: {
    padding: 10,
    marginBottom: 30,
  },
  pageTitle: {
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: 30,
  },
  sectionTitle: {
    marginBottom: 15,
  },
  formControl: {
    width: '100%',
    maxWidth: 'min(500px, calc(100% - 48px))',
  },
  selectThemeContainer: {
    marginBottom: 30,
  },
  instructions: {
    '& a': {
      color: theme.palette.primary.main,
    },
  },
}));

export const ReviewerList = (props) => {
  const classes = reviewerListStyles();
  // const colors = colorStyles();

  const firebaseContext = useContext(FirebaseContext);
  const firebaseDataContext = useContext(FirebaseDataContext);

  // const [readingThemes, setReadingThemes] = useState(false);
  // const [themes, setThemes] = useState([]);
  // const [errorReadingThemes, setErrorReadingThemes] = useState();

  const themes = firebaseDataContext.themes || [];

  const myUid = firebaseContext.user.uid;

  // compute the list of project to review
  const attributedProjectList = {};

  const evaluationRead = firebaseDataContext.evaluationsRead || false;
  if (!firebaseDataContext.themesRead || !firebaseDataContext.definitionsRead || !evaluationRead || !firebaseDataContext.evaluation_flagRead) {
    return <LoadingGrid />;
  }

  const evaluation_flag = firebaseDataContext.evaluation_flag;
  const evaluationDefinitions = (firebaseDataContext.evaluationDefinitions[evaluation_flag] || {});
  const evaluationParameters = evaluationDefinitions.evaluationParameters || {};

  const administrativeReviews = firebaseDataContext.administrativeReviews || {};


  themes.forEach((theme) => {
    // Object.keys(theme.reviewers)
    theme.projects.forEach((projId) => {
      // const [curAapId, curProjId] = projIdentifier.split('_');
      if (
        !(projId in firebaseDataContext.userProjects) ||
        !firebaseDataContext.userProjects[projId].status.submitted) {
        return
      }

      if (evaluationDefinitions && evaluationDefinitions.evaluatedAAPID) {
        if (!evaluationDefinitions.evaluatedAAPID.includes(firebaseDataContext.userProjects[projId].status.aap_id)) {
          return
        }
      }

      if (evaluationParameters.validAdministrativeResult && firebaseDataContext.administrativeReviews) {
        if (
          !(projId in administrativeReviews) ||
          (administrativeReviews[projId].flag !== evaluation_flag) ||
          (administrativeReviews[projId].status !== 'reviewed') ||
          !evaluationParameters.validAdministrativeResult.includes(administrativeReviews[projId].result)
        ) {
          return;
        }
      }

      const revList = theme.reviewers[projId] || [];
      const projAapId = (firebaseDataContext.userProjects[projId] || { status: {} }).status.aap_id || null;
      if (revList.indexOf(myUid) >= 0 && projAapId !== null) {
        if (!Object.keys(attributedProjectList).includes(projAapId)) {
          attributedProjectList[projAapId] = [];
        }

        attributedProjectList[projAapId].push({ themeName: theme.name, projectId: projId });
      }
    });
  });

  const nbProjectsToReview = Object.keys(attributedProjectList).reduce((prev, curKey) => prev + attributedProjectList[curKey].length, 0);
  // const nbProjectsToReview = attributedProjectList.length;





  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography className={classes.pageTitle} variant="h1" component="div">
            Évaluation des projets
          </Typography>
        </Box>
      </Box>

      <Accordion className={classes.paper} sx={{ mt: 0 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <u>
            <strong>Instructions pour votre évaluation des dossiers de candidature BeCircular :</strong>
          </u>
        </AccordionSummary>
        <AccordionDetails className={classes.instructions}>
          <ReviewerInstructions />
        </AccordionDetails>
      </Accordion>

      {nbProjectsToReview === 0 && (
        <Paper className={classes.paper} elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>Vous n'avez aucun projet à évaluer pour le moment</Box>
            </Grid>
          </Grid>
        </Paper>
      )}

      {nbProjectsToReview > 0 && (
        <Paper className={classes.paper} elevation={2}>
          <ProjectsToAnalyze attributedProjectList={attributedProjectList} />
        </Paper>
      )}
    </Container>
  );
};

const projectsToAnalyzeStyles = makeStyles((theme) => ({
  aapNameHeader: {
    fontWeight: 700,
    marginBottom: 10,
  },
  projectLink: {
    margin: '5px 0',
    border: '1px solid #ccc',
    padding: '5px 10px',
    borderRadius: 5,
    display: 'block',
    color: '#333',
    fontWeight: 500,
    // transition:"background-color 150ms linear",

    '&.inEvaluation': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.primary.main,
      // color: 'white',
      // backgroundColor: theme.palette.primary.main,
    },
    '&.evaluated': {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
      // color: 'white',
      // backgroundColor: theme.palette.secondary.main,
    },

    '&:hover': {
      // color:'white',
      color: '#333!important',
      backgroundColor: '#eee!important',
    },
  },
  themeName: {
    fontSize: '.8rem',
    fontStyle: 'italic',
    marginTop: 5,
  },
  listContainer: {
    padding: '10px 20px',
  },
  scoreContainer: {
    padding: 5,
    // background: 'white',
    color: 'white',
    borderRadius: 3,
    fontSize: '.8rem',
    fontWeight: 'bold',
  },
}));

const ProjectsToAnalyze = (props) => {
  const classes = projectsToAnalyzeStyles();
  const colors = colorStyles();

  const url = `${routes.ADMIN}${routes.EVALUATION}`;

  const firebaseDataContext = useContext(FirebaseDataContext);

  const evaluationFlag = firebaseDataContext.evaluation_flag;
  const evaluationDefinition = (firebaseDataContext.evaluationDefinitions[evaluationFlag] || {});
  const evaluationQuestions = evaluationDefinition.evaluationQuestions || [];



  return (
    <>
      {Object.entries(props.attributedProjectList).map((d) => {
        const [aapId, projects] = d;

        return (
          <div key={`tba_${aapId}`}>
            {projects.length > 0 && firebaseDataContext.definitions[aapId] && (
              <div className={classes.listContainer}>
                <div className={classes.aapNameHeader}>{firebaseDataContext.definitions[aapId].name_fr || '???'}</div>
                {firebaseDataContext.definitionsRead && (
                  <Grid container spacing={2}>
                    {projects.sort((p1, p2) => {
                      const pn1 = ((firebaseDataContext.userProjects[p1.projectId] || {}).status || {}).project_number || 0;
                      const pn2 = ((firebaseDataContext.userProjects[p2.projectId] || {}).status || {}).project_number || 0;

                      if (pn1 < pn2) return -1;
                      if (pn1 > pn2) return 1;
                      return 0;
                    })
                      .map((project) => {
                        const evaluationData = firebaseDataContext.evaluations[project.projectId] || {};

                        const projectData = firebaseDataContext.userProjects[project.projectId] || { data: {} };
                        const name = projectData.data.acronyme || '???';

                        const project_number = (projectData.status || {}).project_number || 0;

                        const averageScoreMap = evaluationQuestions.reduce(
                          (prev, q) => {
                            if (q.name in evaluationData && q.eval_type === 'cote') {
                              if (evaluationData[q.name] > 0) {
                                prev.n += 1;
                                prev.s += evaluationData[q.name];
                              }
                            }
                            return prev;
                          },
                          { n: 0, s: 0 }
                        );

                        const averageScore = averageScoreMap.n > 0 ? Math.round((100 * averageScoreMap.s) / averageScoreMap.n) / 100 : null;

                        return (
                          <Grid key={`${project.projectId}`} item xs={12} sm={6} md={4} lg={3}>
                            <Link to={`${url}/${project.projectId}`} state={{ themeName: project.themeName }}>
                              <div className={clsx(classes.projectLink, { inEvaluation: Object.keys(evaluationData).length > 0, evaluated: evaluationData[evaluationFlag] })}>
                                <Box display="flex" alignItems="flex-start">
                                  <div style={{ flex: 1 }}>
                                    <div>
                                      {project_number !== 0 ? `#${project_number} - ` : ''}
                                      {name}
                                    </div>
                                    <div className={classes.themeName}>{project.themeName}</div>
                                  </div>
                                  {averageScore !== null && (
                                    <div
                                      className={clsx(classes.scoreContainer, {
                                        [colors.bckgGreen]: averageScore <= 20,
                                        [colors.bckgBlue]: averageScore <= 15,
                                        [colors.bckgOrange]: averageScore <= 10,
                                        [colors.bckgRed]: averageScore <= 5,
                                      })}
                                    >
                                      {averageScore}
                                    </div>
                                  )}
                                </Box>
                              </div>
                            </Link>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </div>
            )}
          </div>
        );
      })}

      {/* {props.attributedProjectList.map((d) => {
        const [aapId, projects] = d;

        return (
          <div key={`tba_${aapId}`}>
            {projects.length > 0 && (
              <div className={classes.listContainer}>
                <div className={classes.aapNameHeader}>{firebaseDataContext.definitions[aapId].name_fr}</div>
                {firebaseDataContext[`${aapId}Read`] && (
                  <Grid container spacing={2}>
                    {projects.map((project) => {
                      const evaluationData = firebaseDataContext[`evaluation_${aapId}`][project.projectId] || {};

                      const projectData = firebaseDataContext[aapId][project.projectId];
                      const name = (projectData['tab_2'] && projectData['tab_2'].sec0_acronyme) || (projectData['tab_1'] && projectData['tab_1'].sec0_identity) || '???';

                      const averageScoreMap = evaluationQuestions.reduce(
                        (prev, q) => {
                          if (q.name in evaluationData && q.eval_type === 'cote') {
                            if (evaluationData[q.name] > 0) {
                              prev.n += 1;
                              prev.s += evaluationData[q.name];
                            }
                          }
                          return prev;
                        },
                        { n: 0, s: 0 }
                      );

                      const averageScore = averageScoreMap.n > 0 ? Math.round((100 * averageScoreMap.s) / averageScoreMap.n) / 100 : null;

                      return (
                        <Grid key={`${aapId}_${project.projectId}`} item xs={12} sm={6} md={4} lg={3}>
                          <Link
                            to={{
                              pathname: `${url}/${aapId}/${project.projectId}`,
                              state: { themeName: project.themeName },
                            }}
                          >
                            <div className={clsx(classes.projectLink, { inEvaluation: Object.keys(evaluationData).length > 0, evaluated: evaluationData[evaluationFlag] })}>
                              <Box display="flex" alignItems="flex-start">
                                <div style={{ flex: 1 }}>
                                  <div>{name}</div>
                                  <div className={classes.themeName}>{project.themeName}</div>
                                </div>
                                {averageScore !== null && (
                                  <div
                                    className={clsx(classes.scoreContainer, {
                                      [colors.green]: averageScore <= 20,
                                      [colors.blue]: averageScore < 15,
                                      [colors.orange]: averageScore < 10,
                                      [colors.red]: averageScore < 5,
                                    })}
                                  >
                                    {averageScore}
                                  </div>
                                )}
                              </Box>
                            </div>
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </div>
            )}
          </div>
        );
      })} */}
    </>
  );
};
