// import { useEffect, useRef } from 'react';
// import { withRouter } from 'react-router-dom';
// import PropTypes from 'prop-types';

// const ScrollIntoView = ({element, children, location }) => {
//   const prevLocation = useRef();

//   useEffect(() => {
//     if (prevLocation.current !== location.pathname) {
//       // window.scrollTo(0, 0);
//       // element.ScrollIntoView();
//       if (element) {
//         // element.scrollIntoView();
//         element.scrollTop = 0
//       }
//       prevLocation.current = location.pathname;
//     }
//   }, [element, location]);

//   return children

// };

// ScrollIntoView.propTypes = {
//   children: PropTypes.node,
//   location: PropTypes.object,
// };

// export default withRouter(ScrollIntoView);

// ScrollToTop.jsx
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

const ScrollToTop = ({ element, ...props }) => {
  const location = useLocation();
  const prevLocation = useRef();

  useEffect(() => {
    if (prevLocation.current !== location.pathname) {
      if (element) {
        element.scrollTop = 0;
      }
      prevLocation.current = location.pathname;
    }
  }, [element, location]);

  return <>{props.children}</>;
};

export default ScrollToTop;
