import { useContext, useState } from 'react';
/* CONTEXT */
import { FirebaseDataContext } from '../../../../firebase';

import { TicketBox } from './ticketBox';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const helpdeskStyle = makeStyles((theme) => ({
  pageTitle: {
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: 30,
  },
  listHeader: {
    flexGrow: 1,
    '&:not(:first-child)': {
      marginTop: 30,
    },
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: 20,
  },
  linkContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '21%',
    marginRight: '3%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginRight: '5%',
    },
  },
  noTicket: {
    fontWeight: 500,
  },
}));

export const HelpdeskList = () => {
  const helpdeskClasses = helpdeskStyle();

  const firebaseDataContext = useContext(FirebaseDataContext);

  const dataLoaded = firebaseDataContext.helpdeskTicketsRead;

  const [showClosedTicket, setShowClosedTicket] = useState(false);

  if (!dataLoaded) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress thickness={6} size={50} />
      </Grid>
    );
  }

  const helpdeskTickets = firebaseDataContext.helpdeskTickets || {};

  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography className={helpdeskClasses.pageTitle} variant="h1" component="div">
            Helpdesk
          </Typography>
        </Box>

        <Box>
          <Button
            variant="contained"
            onClick={() => {
              setShowClosedTicket(!showClosedTicket);
            }}
            className={helpdeskClasses.switchButton}
          >
            Voir les tickets {showClosedTicket ? 'ouverts' : 'fermés'}
          </Button>
        </Box>
      </Box>
      {showClosedTicket ? (
        <Box display="flex">
          <Box style={{ flex: 1 }}>
            <Typography variant="h6" className={helpdeskClasses.listHeader}>
              Closed Tickets
            </Typography>

            {Object.entries(helpdeskTickets).filter((t) => t[1].status.solved).length <= 0 ? (
              <div className={helpdeskClasses.noTicket}>
                <p>Il n'y aucun ticket fermé</p>
              </div>
            ) : (
              <div className="ticket-container">
                {Object.entries(helpdeskTickets)
                  .filter((t) => t[1].status.solved)
                  .sort((t1, t2) => {

                    const topic1 = t1[1].status.topic;
                    const topic2 = t2[1].status.topic;
                    return topic1 === topic2 ? 0 : topic1 < topic2 ? -1 : 1;
                  })
                  .map((oT) => {
                    const [ticket_id, ticket_data] = oT;
                    return <TicketBox key={`ticket_${ticket_id}`} ticket_id={ticket_id} ticket_data={ticket_data} />;
                  })}
              </div>
            )}
          </Box>
        </Box>
      ) : (
        <Box display="flex">
          <Box style={{ flex: 1 }}>
            <Typography variant="h6" className={helpdeskClasses.listHeader}>
              Opened Tickets
            </Typography>

            {Object.entries(helpdeskTickets).filter((t) => !t[1].status.solved).length <= 0 ? (
              <div className={helpdeskClasses.noTicket}>
                <p>Il n'y aucun ticket ouvert</p>
              </div>
            ) : (
              <div className="ticket-container">
                {Object.entries(helpdeskTickets)
                  .filter((t) => !t[1].status.solved)
                  .sort(([t1id, t1], [t2id, t2]) => {
                    // console.log(t1, t2)
                    const lm1 = t1.messages.slice(-1)[0]
                    const lm2 = t2.messages.slice(-1)[0]

                    const sender1 = lm1.sender || ""
                    const sender2 = lm2.sender || ""

                    if (sender1 === "client" && sender2 !== "client") return -1
                    if (sender1 !== "client" && sender2 === "client") return 1

                    const date1 = lm1.time
                    const date2 = lm2.time

                    return date2 - date1

                  })
                  .map((oT) => {
                    const [ticket_id, ticket_data] = oT;
                    return <TicketBox key={`ticket_${ticket_id}`} ticket_id={ticket_id} ticket_data={ticket_data} />;
                  })}
              </div>
            )}
          </Box>
        </Box>
      )}
    </Container>
  );
};
