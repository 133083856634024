import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FirebaseContext } from '../../firebase';

import logo from '../../assets/images/logo.jpg';
import * as routes from '../../routing/routes';

/* LAYOUT */
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import useScrollTrigger from '@mui/material/useScrollTrigger';

const useStyles = makeStyles((theme) => ({
  logoutButton: {
    color: 'white',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.main,
      color: theme.palette.primary.main,
    },
  },
  toolbarEmail: {
    color: 'white',
    fontSize: '.8rem',
    fontStyle: 'italic',
    paddingRight: 10,
  },
  logo: {
    // margin: theme.spacing(1),
    height: 50,
    maxWidth: 150,
    marginRight: 20,
  },
}));

function ElevationScroll(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    // target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export const AdminToolbar = (props) => {
  const firebaseState = useContext(FirebaseContext);
  const classes = useStyles();
  // const history = useHistory();
  const navigate = useNavigate();

  /* Redirect user after signout effect */
  useEffect(() => {
    if (!firebaseState.user) {
      // history.replace(routes.HOME);
      navigate(routes.LOGIN, {replace:true})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseState.user]);

  const handleLogout = () => {
    firebaseState.app.doSignOut().then(
      ()=>{navigate(routes.LOGIN, {replace:true})}
    );
  };

  return (
    <>
      <ElevationScroll>
        <AppBar >
          <Toolbar>
            <img className={classes.logo} src={logo} alt="logo" />
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              Panneau d'Administration
            </Typography>
            <Typography className={classes.toolbarEmail}>{firebaseState.user && firebaseState.user.email}</Typography>
            <Button variant="contained" onClick={handleLogout} className={classes.logoutButton}>
              Déconnexion
            </Button>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </>
  );
};
