import { useContext } from 'react';

/* Routing */
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import * as routes from './routing/routes';

/* Routed components */
import { Login } from './components/login';
import { Admin } from './components/admin';

/* Global Styles */
import './App.css';

/* Context */
import { FirebaseContext } from './firebase';

/* Laout */
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';

// import Grid from '@material-ui/core/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress';

/* THEME */
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
// import { createMuiTheme } from '@material-ui/core/styles';
// import { ThemeProvider } from '@material-ui/styles';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#005aad',
      },
      secondary: {
        main: '#42aa06',
        light: '#dffdce',
      },
      warning: {
        main: '#FD6200',
      },
      info: {
        main: '#C70083',
      },
      error: {
        main: '#F44436',
        light: '#FFE3DF',
      },
    },
  })
);

function App() {
  const firebaseState = useContext(FirebaseContext);
  const { pathname } = useLocation();

  if (!firebaseState.userInit) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress thickness={6} size={50} />
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        {/* Redirect url with ending / */}
        <Route path="/:url*(/+)" element={<Navigate to={pathname.slice(0, -1)} />} />
        {/* <Navigate from="/:url*(/+)" to={pathname.slice(0, -1)} /> */}

        <Route path={routes.LOGIN} element={<Login />} />

        <Route path={`${routes.ADMIN}/*`} element={<Admin />} />
        <Route path="*" element={<Navigate to={routes.LOGIN} replace />} />

        {/* <div>
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="abc">
            Edit <code>src/App.js</code> and save to reload.
          </p>

        </header>
      </div> */}
      </Routes>
    </ThemeProvider>
  );
}

export default App;
