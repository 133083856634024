import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';

import { initializeApp } from 'firebase/app';
import { getAuth, signOut, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence } from 'firebase/auth';
// import 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence, connectFirestoreEmulator, doc, query, onSnapshot, getDocs, collection, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import { getStorage, connectStorageEmulator, ref, getDownloadURL } from 'firebase/storage';
import { getFunctions, connectFunctionsEmulator, httpsCallable } from 'firebase/functions';

import { APP_YEAR } from "../constants";

/* CONFIG */
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  use_emulators: process.env.REACT_APP_USE_EMULATORS === 'true',
  use_functions_emulators: process.env.REACT_APP_USE_FUNCTIONS_EMULATOR === 'true',
};

const saveFile = (href, fileName) => {
  const a = document.createElement('a');
  a.href = href;
  a.download = fileName;
  a.dispatchEvent(new MouseEvent('click'));
};

const convert_datauristring_to_blob = (input_stream) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(input_stream.split(',')[1]);

  // separate out the mime component
  const mimeString = input_stream.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  const ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  const blob = new Blob([ab], { type: mimeString });

  return blob;
};

const downloadFileWithXHR = (fileLink, filename) => {
  return new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
      var blob = xhr.response;
      const blobUrl = window.URL.createObjectURL(blob);
      saveFile(blobUrl, filename);
      URL.revokeObjectURL(blobUrl);
      resolve(0);
    };
    xhr.onerror = (event) => {
      reject(event);
    };
    xhr.open('GET', fileLink, true); // set async flag to true
    xhr.send();
  });
};

const firebaseRegion = 'europe-west1';

/* CLASS */
class Firebase {
  constructor() {
    const app = initializeApp(config);

    this.auth = getAuth(app);
    this.db = getFirestore(app);
    this.storage = getStorage(app);
    this.functions = getFunctions(app, firebaseRegion);

    this.TESTING = false;
    if (this.TESTING) {
      console.warn('**** TESTING ENABLED ****');
    }

    if (config.use_emulators) {
      console.warn('** using emulators **');
      connectFirestoreEmulator(this.db, 'localhost', 9003);
      connectStorageEmulator(this.storage, 'localhost', 9005);
    }
    if (config.use_functions_emulators) {
      console.warn('** using functions emulator **');
      connectFunctionsEmulator(this.functions, 'localhost', 9002);
    }

    if (false) {
      enableIndexedDbPersistence(this.db).catch((err) => {
        console.error('Error unabling persitence');
        console.error(err);
        // if (err.code == 'failed-precondition') {
        // } else if (err.code == 'unimplemented') {
        // }
      });
    }

    // if (process.env.NODE_ENV === 'development') {
    //   console.warn('**** USING LOCAL HOST');
    //   // this.functions.useEmulator('localhost', 9002);
    //   connectFirestoreEmulator(this.db, 'localhost', 9003);
    //   connectFunctionsEmulator(this.functions, 'localhost', 9002);
    //   connectStorageEmulator(this.storage, 'localhost', 9005);
    // }
  }

  doSignInWithEmailAndPassword = (email, password, persist = false) => {
    const persistence = persist ? browserLocalPersistence : browserSessionPersistence;
    return setPersistence(this.auth, persistence).then(
      () => signInWithEmailAndPassword(this.auth, email, password),
      (persistenceError) => {
        console.error(persistenceError);
      }
    );
  };

  doSignOut = () => {
    return new Promise((resolve, reject) => {
      signOut(this.auth).then(() => resolve());
    });
  };

  // setReview = (aapId, projectId, state) => {
  //   return this.db.collection('review').doc('admin').collection(aapId).doc(projectId).set(state);
  // };

  setAdministrativeReview = (projectId, state) => {
    return setDoc(doc(this.db, `evaluations_administratives/${projectId}`), state);
  };

  setEvaluation = (reviewerUid, projectId, state) => {
    return setDoc(doc(this.db, `evaluations/projects/${reviewerUid}/${projectId}`), state);
    // return this.db.collection('evaluations').doc(reviewerUid).collection(aapId).doc(projectId).set(state);
  };

  setGlobalEvaluation = (reviewerUid, projectId, state) => {
    return setDoc(doc(this.db, `evaluations_globales/${projectId}`), state);
  };

  getFileDownloadUrl = (fileref) => {
    const pathReference = ref(this.storage, fileref);
    return getDownloadURL(pathReference);

    // const fileref_splitted = fileref.split('/');

    // let storage_ref = this.storage.ref();
    // fileref_splitted.forEach((r) => {
    //   storage_ref = storage_ref.child(r);
    // });

    // return storage_ref.getDownloadURL();
  };

  downloadProjectFile = (fileref, downloadFilename) => {
    return new Promise((resolve, reject) => {
      this.getFileDownloadUrl(fileref).then(
        (url) => {
          return downloadFileWithXHR(url, downloadFilename).then(
            () => {
              resolve();
            },
            (error) => {
              reject(error);
            }
          );
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  downloadProjectPDFFromSource(projectId, userId) {
    return httpsCallable(this.functions, 'downloadProjectPDF')({ projectId: projectId, user_uid: userId });
  }

  downloadProjectPDF(projectStatus) {
    const submissionDate = projectStatus.submitted_date || null;
    if (submissionDate == null) Promise.resolve(0);

    const projectPDFDate = (projectStatus.projectPDF || {}).fileDate || null;
    const projectPDFFile = (projectStatus.projectPDF || {}).fileUrl || null;

    const project_filename = `ASK_${APP_YEAR}_Formulaire_${projectStatus.uid}_${projectStatus.project_id}`;
    //${firebaseDataContext.definitions[aapId].name_fr.replace(' ', '_')}

    if (projectPDFDate == null || projectPDFFile == null || projectPDFDate < submissionDate) {
      return this.downloadProjectPDFFromSource(`${projectStatus.uid}__${projectStatus.project_id}`, projectStatus.uid).then((promisedData) => {
        const pdfData = promisedData.data;

        if (pdfData.pdfOutputDataUriString) {
          const blob = convert_datauristring_to_blob(pdfData.pdfOutputDataUriString);

          const blobUrl = window.URL.createObjectURL(blob);
          saveFile(blobUrl, project_filename);
          URL.revokeObjectURL(blobUrl);
        } else {
          return Promise.reject('Incorrect received data', pdfData);
        }
      });
    } else {
      return downloadFileWithXHR(projectPDFFile, project_filename);
    }
  }

  // closeReview = (review) => {
  //   return this.functions.httpsCallable('closeReview')(review);
  // };
  closeAdministrativeReview = (projectId, review) => {
    return updateDoc(doc(this.db, `evaluations_administratives/${projectId}`), review);
  };

  closeAdmnistrativeReviewWithEmail = (projectId, review) => {
    return httpsCallable(
      this.functions,
      'closeAdministrativeReview'
    )({
      projectId,
      review,
      testing: this.TESTING,
    });
  };

  closeEvaluationGlobal = (projectId, globalEvaluation) => {
    return httpsCallable(
      this.functions,
      'closeEvaluationGlobal'
    )({
      ...globalEvaluation,
      projectId,
      testing: this.TESTING,
    });
  };

  listUsers = () => {
    return httpsCallable(this.functions, 'listUsers')();
  };

  setUserClaims = (uid, claim, remove) => {
    return httpsCallable(this.functions, 'setUserClaims')({ uid, claim, remove });
  };

  // listTheme = () => {
  //   return getDocs(collection(this.db, 'themes'));
  //   // return this.db.collection('review').doc('themes').collection('list').get();
  // };

  addTheme = (themeName) => {
    return httpsCallable(this.functions, 'createTheme')({ themeName });
  };

  removeTheme = (themeName) => {
    // return this.db.collection('review').doc('themes').collection('list').doc(themeName).delete();
    return deleteDoc(doc(this.db, `themes/${themeName}`));
  };

  updateThemeAdmin = (themeName, themeAdmin) => {
    return updateDoc(doc(this.db, `themes/${themeName}`), { themeAdmin: themeAdmin });
    // return this.db.collection('review').doc('themes').collection('list').doc(themeName).update({ themeAdmin: themeAdmin });
  };

  updateThemeProjects = (themeName, themeProjects) => {
    return updateDoc(doc(this.db, `themes/${themeName}`), { projects: themeProjects });
    // return this.db.collection('review').doc('themes').collection('list').doc(themeName).update({ projects: themeProjects });
  };

  listReviewers = () => {
    return httpsCallable(this.functions, 'listReviewers')();
  };

  getEvaluationsForReviewer = (revId) => {
    return getDocs(collection(this.db, `evaluations/projects/${revId}`)).then((reviewerEvaluations) => {
      const evaluations = { reviewerId: revId, reviewerEvals: {} };

      if (reviewerEvaluations.empty) return evaluations;

      const revEval = {};
      reviewerEvaluations.forEach((rE) => {
        revEval[rE.id] = rE.data();
      });
      evaluations.reviewerEvals = revEval;
      return evaluations;
    });
  };

  editReviewersForProject = (themeName, projectId, reviewerListUid) => {
    return updateDoc(doc(this.db, `themes/${themeName}`), { [`reviewers.${projectId}`]: reviewerListUid });
    // return this.db
    //   .collection('review')
    //   .doc('themes')
    //   .collection('list')
    //   .doc(themeName)
    //   .update({ [`reviewers.${aapId}_${projectId}`]: reviewerListUid });
  };

  // setTheme = ()=>{
  //   return this.db.collection('review').doc('themes').collection('list').doc('themeA').set({new:43});
  // }

  parse_definitions = (aap_IDS) => {
    const definitions = {};
    const read_aap_promise = [];
    aap_IDS.forEach((aap_Id) => {
      const doc_prom = getDocs(collection(this.db, `/aap/formulaires/${aap_Id}`)).then(
        (aap_docs) => {
          const aap_content = { tabs: [], id: aap_Id };
          aap_docs.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            const doc_data = doc.data();
            if (doc_data) {
              if (doc.id === 'status') {
                for (const k in doc_data) {
                  aap_content[k] = k === 'deadline' ? doc_data[k].toDate() : doc_data[k];
                }
              } else {
                aap_content.tabs.push(doc_data);
              }
            }
          });

          aap_content.tabs = aap_content.tabs.sort((a, b) => {
            const ai = a.tab_index || 0;
            const bi = b.tab_index || 0;
            if (ai > bi) return 1;
            else if (ai < bi) return -1;
            return 0;
          });

          definitions[aap_Id] = aap_content;
        },
        (aap_eror) => {
          console.error('parsing AAP error', aap_eror);
        }
      );

      read_aap_promise.push(doc_prom);
    });

    return Promise.all(read_aap_promise).then(
      () => {
        return definitions;
      },
      () => {
        console.error('reading aap definitions promise failed');
      }
    );
  };

  helpdeskResponse = (ticketId, response) => {
    return httpsCallable(this.functions, 'helpdeskResponse')({ ticketId, response });
  };

  closeHelpdeskTicket = (ticketId) => {
    return httpsCallable(this.functions, 'closeHelpdeskTicket')({ ticketId });
  };
}

/* CONTEXT */
const FirebaseApp = new Firebase();
const initialContext = {
  userInit: false,
  user: null,
  claims: {},
  app: FirebaseApp,
};

const FirebaseContext = React.createContext(initialContext);

const FirebaseProvider = (props) => {
  const [firebaseState, setFirebaseState] = useState(initialContext);

  /* auth state change */
  useEffect(() => {
    return firebaseState.app.auth.onAuthStateChanged((user) => {
      /* checking claims */
      let claimCheck;
      if (user) {
        claimCheck = firebaseState.app.auth.currentUser
          .getIdTokenResult()
          .then((idTokenResult) => {
            return idTokenResult.claims;
          })
          .catch((error) => {
            console.error(error);
            return {};
          });
      } else {
        claimCheck = {};
      }

      Promise.all([claimCheck]).then((res) => {
        const [claims] = res;
        const { admin, reviewer, master, themeAdmin, helpdesk } = claims;

        setFirebaseState((prevFirebaseState) => {
          return {
            ...prevFirebaseState,
            user: user,
            userInit: true,
            claims: {
              admin,
              reviewer,
              master,
              themeAdmin,
              helpdesk,
            },
          };
        });
      });
    });
  }, [firebaseState.app.auth]);

  return <FirebaseContext.Provider value={firebaseState}>{props.children}</FirebaseContext.Provider>;
};

/* DATA CONTEXT */
// const aapIDs = ['aap1', 'aap2', 'aap3', 'aap4', 'aap5'];
const FirebaseDataContext = React.createContext(null);

const FirebaseDataProvider = (props) => {
  // define context
  const firebaseState = useContext(FirebaseContext);

  const getEvaluationOfReviewers = useCallback(
    (userList) => {
      const all_read_promises = [];
      userList.forEach((u) => {
        if (!u.customClaims || !u.customClaims.reviewer) return;

        all_read_promises.push(firebaseState.app.getEvaluationsForReviewer(u.uid));
      });

      return new Promise((resolve, reject) => {
        Promise.all(all_read_promises).then(
          (evals) => {
            const all_evaluations = {};
            evals.forEach((revObj) => {
              all_evaluations[revObj.reviewerId] = revObj.reviewerEvals;
            });

            resolve(all_evaluations);
          },
          (err) => {
            console.error('error reading evaluations of reviewers', err);
            reject();
          }
        );
      });
    },
    [firebaseState.app]
  );

  const [firebaseDataState, setFirebaseDataState] = useState({
    definitionsRead: false,
    projectsRead: false,
    reviewsRead: false,
    evaluationsRead: false,
    aapIDs: [],
    getEvaluationOfReviewers: getEvaluationOfReviewers,
    removeTestProject: false,
    helpdeskTicketsRead: false,
    helpdeskTickets: {},
  });

  useEffect(() => {
    const unregisterData = [];

    //start reading AAP definitions
    if (props.user) {
      const form_def_doc = doc(firebaseState.app.db, '/aap/formulaires');

      const defSnapshot = onSnapshot(
        form_def_doc,
        (form_def) => {
          let aap_IDS = [];
          if (form_def.exists()) {
            const form_data = form_def.data();
            aap_IDS = form_data.aap_id || [];
          }

          firebaseState.app.parse_definitions(aap_IDS).then((definitions) => {
            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                definitions: definitions,
                aapIDs: Object.keys(definitions),
                definitionsRead: true,
              };
            });
          });
        },
        (form_error) => {
          console.error('Error reading AAP definitions');
          console.error(form_error);
        }
      );
      unregisterData.push(defSnapshot);
    }

    if (true) {
      // reading submitted projects
      if (props.admin || props.themeAdmin || props.reviewer) {
        const projectSnapshot = onSnapshot(
          collection(firebaseState.app.db, '/projects'),
          (collections_data) => {
            const userProjects = {};

            if (firebaseState.app.TESTING) console.warn('!!!!! filtering projects !!!! ');
            if (!collections_data.empty) {
              collections_data.forEach((UP) => {
                if (firebaseState.app.TESTING && !UP.data().status.test) return;
                if (!firebaseState.app.TESTING && UP.data().status.test) return;
                userProjects[UP.id] = UP.data();
              });
            }

            // console.log(userProjects)
            // userProjects['b612QQMKLkb6oj4pMq7JNtxUwL23__1673892923175'].status.submitted = true;

            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                userProjects: userProjects,
                projectsRead: true,
              };
            });
          },
          (error) => {
            console.error('Error reading project data');
            console.error(error);
          }
        );
        unregisterData.push(projectSnapshot);

        /*
        // reading projects entries
        aapIDs.forEach((aapId) => {
          const snapshot = firebaseState.app.db.collection(aapId).onSnapshot(
            (queryDef) => {
              const submitted = {};
              queryDef.forEach((doc) => {
                submitted[doc.id] = doc.data();
              });
 
              setFirebaseDataState((prevState) => {
                return {
                  ...prevState,
                  [aapId]: submitted,
                  [`${aapId}Read`]: true,
                };
              });
            },
            (error) => {
              console.error('Error reading aapId', aapId);
              console.error(error);
            }
          );
 
          // add snapshot for future unregistration
          unregisterData.push(snapshot);
        });
        */
      }

      // reading administrative reviews
      if (props.master || props.admin || props.themeAdmin || props.reviewer) {
        const reviewSnapshot = onSnapshot(
          collection(firebaseState.app.db, 'evaluations_administratives'),
          { includeMetadataChanges: true },
          (review_data) => {
            const reviews = {};

            if (!review_data.empty) {
              review_data.forEach((rev) => {
                reviews[rev.id] = rev.data();
              });
            }

            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                administrativeReviews: reviews,
                administrativeReviewsRead: true,
              };
            });
          },
          (error) => {
            console.error('Error reading administrative reviews');
            console.error(error);
          }
        );
        unregisterData.push(reviewSnapshot);
      }

      // reading themes
      if (props.master || props.themeAdmin || props.reviewer) {
        const reviewSnapshot = onSnapshot(
          collection(firebaseState.app.db, 'themes'),
          (themes_doc) => {
            const themes = [];

            if (!themes_doc.empty) {
              themes_doc.forEach((t) => {
                themes.push({ name: t.id, ...t.data() });
              });
            }

            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                themes: themes,
                themesRead: true,
              };
            });
          },
          (error) => {
            console.error('Error reading administrative reviews');
            console.error(error);
          }
        );
        unregisterData.push(reviewSnapshot);
      }
      // reading evaluations globales
      if (props.master || props.themeAdmin) {
        const evalGlobalSnapshot = onSnapshot(
          collection(firebaseState.app.db, 'evaluations_globales'),
          (eval_doc) => {
            const evals = {};
            if (!eval_doc.empty) {
              eval_doc.forEach((p) => {
                evals[p.id] = p.data();
              });
            }

            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                evaluationsGlobales: evals,
                evaluationsGlobalesRead: true,
              };
            });
          },
          (error) => {
            console.error('Error reading evaluations globales');
            console.error(error);
          }
        );
        unregisterData.push(evalGlobalSnapshot);
      }

      // reading evaluation flag
      if (props.themeAdmin || props.reviewer || props.admin) {
        const flag_snapshot = onSnapshot(
          doc(firebaseState.app.db, `evaluations/flags`),
          (flagQuery) => {
            if (!flagQuery.exists()) {
              return setFirebaseDataState((prevState) => {
                return {
                  ...prevState,
                  evaluation_flag: 'evaluated',
                  evaluation_flagRead: true,
                  evaluationDefinitions: [],
                };
              });
            }
            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                evaluation_flag: flagQuery.data().evaluation_flag || 'evaluated',
                evaluation_flagRead: true,
                evaluationDefinitions: flagQuery.data(),
              };
            });
          },
          (error) => {
            console.error('Error reading flags');
            console.error(error);
          }
        );
        unregisterData.push(flag_snapshot);
      }

      if (props.reviewer) {
        const reviewerUid = props.user.uid;

        // reading current reviewer evaluations
        const reviewSnapshot = onSnapshot(
          collection(firebaseState.app.db, `evaluations/projects/${reviewerUid}`),
          (queryDef) => {
            const evaluationDone = {};
            queryDef.forEach((doc) => {
              evaluationDone[doc.id] = doc.data();
            });
            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                evaluations: evaluationDone,
                evaluationsRead: true,
              };
            });
          },
          (error) => {
            console.error('Error reading current reviewer evaluations');
            console.error(error);
          }
        );
        unregisterData.push(reviewSnapshot);
      }

      if (props.helpdesk) {
        // reading current reviewer evaluations
        const ticketQuery = query(collection(firebaseState.app.db, `helpdesk`));
        const helpdeskTicketsSnapshot = onSnapshot(
          ticketQuery,
          (queryDef) => {
            const tickets = {};
            queryDef.forEach((doc) => {
              tickets[doc.id] = doc.data();
            });

            setFirebaseDataState((prevState) => {
              return {
                ...prevState,
                helpdeskTicketsRead: true,
                helpdeskTickets: tickets,
              };
            });
          },
          (error) => {
            console.error('Error reading opened helpdesk tickets');
            console.error(error);
          }
        );
        unregisterData.push(helpdeskTicketsSnapshot);
      }
    }

    /* return unsubscribing function */
    return () => {
      unregisterData.forEach((ud) => ud());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.user, props.admin, props.reviewer]);

  return <FirebaseDataContext.Provider value={firebaseDataState}>{props.children}</FirebaseDataContext.Provider>;
};

/* USER LIST */
const defaultUsersList = {
  readingUsers: true,
  usersList: [],
  errorReadingUsers: null,
  editingUserClaim: false,
  readUsersList: () => { },
  editUserClaim: () => { },
};
const UsersListContext = React.createContext(defaultUsersList);

const UsersListProvider = (props) => {
  const firebaseContext = useContext(FirebaseContext);

  const readUsersList = () => {
    // return;
    setListState((prevList) => ({
      ...prevList,
      readingUsers: true,
      usersList: [],
      errorReadingUsers: null,
    }));

    firebaseContext.app.listUsers().then(
      (userData) => {
        setListState((prevList) => ({
          ...prevList,
          readingUsers: false,
          usersList: userData.data.users || [],
          errorReadingUsers: null,
        }));
      },
      (error) => {
        console.error('error', error);
        setListState((prevList) => ({
          ...prevList,
          readingUsers: false,
          usersList: [],
          errorReadingUsers: { error: 'Une erreur est survenue, veuillez réessayer' },
        }));
      }
    );
  };

  const editUserClaim = (claimData) => {
    if (listState.editingUserClaim) return;

    setListState((prevList) => ({
      ...prevList,
      editingUserClaim: true,
    }));

    firebaseContext.app.setUserClaims(claimData.uid, claimData.claim, !claimData.value).then(
      (claimResult) => {
        setListState((prevList) => {
          const updatedUsersList = prevList.usersList.map((u) => {
            if (u.uid !== claimData.uid) return u;

            u.customClaims = u.customClaims ? { ...u.customClaims, [claimData.claim]: claimData.value } : { [claimData.claim]: claimData.value };
            return u;
          });
          return {
            ...prevList,
            usersList: updatedUsersList,
            editingUserClaim: false,
          };
        });
      },
      (claimError) => {
        console.error(claimError);
        setListState((prevList) => ({
          ...prevList,
          editingUserClaim: false,
        }));
      }
    );
  };

  const [listState, setListState] = useState({ ...defaultUsersList, readUsersList, editUserClaim });

  useEffect(() => {
    readUsersList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <UsersListContext.Provider value={listState}>{props.children}</UsersListContext.Provider>;
};

/* REVIEWER LIST */
const defaultReviewersList = {
  readingReviewers: true,
  reviewersList: [],
  errorReadingReviewers: null,
  readReviewersList: () => { },
  reviewersEvaluations: {},
};
const ReviewersListContext = React.createContext(defaultReviewersList);

const evalSnapshots = [];

const ReviewersListProvider = (props) => {
  const firebaseContext = useContext(FirebaseContext);
  const mounted = useRef(true);
  // const [reviewersEvaluations, setReviewersEvaluations] = useState({});
  // const [evalSnapshots, setEvalSnapshots] = useState([]);

  const readReviewersList = () => {
    setReviewersListState((prevList) => ({
      ...prevList,
      readingReviewers: true,
      reviewersList: [],
      errorReadingReviewers: null,
    }));

    firebaseContext.app.listReviewers().then(
      (userData) => {
        if (!mounted.current) return;
        setReviewersListState((prevList) => ({
          ...prevList,
          readingReviewers: false,
          reviewersList: userData.data.users || [],
          errorReadingReviewers: null,
        }));
      },
      (error) => {
        console.error('error', error);
        if (!mounted.current) return;
        setReviewersListState((prevList) => ({
          ...prevList,
          readingReviewers: false,
          reviewersList: [],
          errorReadingReviewers: { error: 'Une erreur est survenue, veuillez réessayer' },
        }));
      }
    );
  };

  const [reviewersListState, setReviewersListState] = useState({ ...defaultReviewersList, readReviewersList });

  // unregister snapshots
  useEffect(() => {
    return () => {
      evalSnapshots.forEach((snap) => snap());
      evalSnapshots.splice(0, evalSnapshots.length);

      // setEvalSnapshots((prevSnap) => {
      //   prevSnap.forEach((snap) => {
      //     snap();
      //   });
      //   return [];
      // });
    };
  }, []);

  useEffect(() => {
    readReviewersList();
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startEvaluationSnapshot = useCallback(
    (revUid) => {
      const revSnapshot = onSnapshot(
        collection(firebaseContext.app.db, `/evaluations/projects/${revUid}`),
        (collections_data) => {
          const revProjects = {};

          if (!collections_data.empty) {
            collections_data.forEach((UP) => {
              revProjects[UP.id] = UP.data();
            });
          }

          // setReviewersEvaluations((prevEval) => {
          //   return {
          //     ...prevEval,
          //     [revUid]: revProjects,
          //   };
          // });

          setReviewersListState((prevState) => {
            return {
              ...prevState,
              reviewersEvaluations: {
                ...prevState.reviewersEvaluations,
                [revUid]: revProjects,
              },
            };
          });
        },
        (error) => {
          console.error('Error reading evaluation data');
          console.error(error);
        }
      );

      return revSnapshot;
    },
    [firebaseContext.app.db]
  );

  useEffect(() => {
    if (reviewersListState.readingReviewers) return;

    const untrackedReviewers = [];
    reviewersListState.reviewersList.forEach((rev) => {
      if (!(rev.uid in reviewersListState.reviewersEvaluations)) untrackedReviewers.push(rev.uid);
    });

    if (untrackedReviewers.length === 0) return;

    untrackedReviewers.forEach((rev) => {
      const snap = startEvaluationSnapshot(rev);
      evalSnapshots.push(snap);
      // setEvalSnapshots((prevSnap) => [...prevSnap, snap]);
    });


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewersListState.readingReviewers,]);

  return <ReviewersListContext.Provider value={reviewersListState}>{props.children}</ReviewersListContext.Provider>;
};

/* EXPORT */
export { FirebaseContext, FirebaseProvider, FirebaseDataContext, FirebaseDataProvider, UsersListContext, UsersListProvider, ReviewersListContext, ReviewersListProvider };
