import { forwardRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const SnackError = (props) => {
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.open} autoHideDuration={4000} onClose={props.handleClose}>
      <Alert onClose={props.handleClose} severity="error" sx={{ width: '100%' }}>
        {props.message ?? 'Une erreur est survenue !!'}
      </Alert>
    </Snackbar>
  );
};
