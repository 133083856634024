import { useContext } from 'react';
import { UsersListContext } from '../../../../firebase';

import { UserTable } from './userTable';

/* LAYOUT */
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CachedIcon from '@mui/icons-material/Cached';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));

export const ManageUsers = () => {
  const classes = useStyles();

  const usersListContext = useContext(UsersListContext);

  if (usersListContext.readingUsers) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress thickness={6} size={50} />
      </Grid>
    );
  }
  if (usersListContext.errorReadingUsers) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <p className={classes.errorMessage}>{usersListContext.errorReadingUsers.error} </p>
        <Box>
          <IconButton aria-label="refresh" color="primary" onClick={usersListContext.readUsersList}>
            <CachedIcon fontSize="large" />
          </IconButton>
        </Box>
      </Grid>
    );
  }
  return (
    <UserTable
      readUsersList={usersListContext.readUsersList}
      userList={usersListContext.usersList}
      editingUserClaim={usersListContext.editingUserClaim}
      editUserClaim={usersListContext.editUserClaim}
    />
  );
};
