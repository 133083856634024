import { useCallback, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FirebaseDataContext, FirebaseContext } from '../../../../firebase';
import { ADMIN, HELPDESK } from '../../../../routing/routes';

import { SnackError } from '../../../utilities/snackError';

import { makeStyles, useTheme } from '@mui/styles';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

const viewTicketStyle = makeStyles((theme) => ({
  detail: {
    padding: 10,
    marginBottom: 30,
  },
  date: {
    color: '#666',
    fontSize: '.8rem',
    textAlign: 'center',
  },
  messageContainer: {
    display: 'grid',
  },
  message: {
    maxWidth: '80%',
    color: 'white',
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    borderRadius: 5,
    display: 'inline-block',
    justifySelf: 'end',
    whiteSpace: 'pre-line',
    '&.client': {
      backgroundColor: theme.palette.primary.main,
      justifySelf: 'start',
    },
  },
}));

export const HelpdeskViewTicket = () => {
  const firebaseDataContext = useContext(FirebaseDataContext);
  const firebaseContext = useContext(FirebaseContext);

  const navigate = useNavigate();
  const theme = useTheme();
  const [sendingResponse, setSendingResponse] = useState(false);

  const viewTicketClasses = viewTicketStyle();

  const { ticketId } = useParams();

  /* SNACK */
  const [snackOpened, setSnackOpened] = useState(false);

  useEffect(() => {
    if (!firebaseDataContext.helpdeskTicketsRead || !(ticketId in firebaseDataContext.helpdeskTickets)) {
      navigate(ADMIN + HELPDESK, { replace: true });
    }
  }, [ticketId, firebaseDataContext.helpdeskTicketsRead, firebaseDataContext.helpdeskTickets, navigate]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const ticket = (firebaseDataContext.helpdeskTickets || {})[ticketId] || { messages: [], status: {} };

  const sendResponse = useCallback(
    async (response) => {
      ticket.status.updating = true;
      setSendingResponse(true);

      try {
        await firebaseContext.app.helpdeskResponse(ticketId, response);
        setSendingResponse(false);
      } catch (e) {
        console.error(e);
        ticket.status.updating = false;
        setSendingResponse(false);
        setSnackOpened(true);
      }
    },
    [ticketId, firebaseContext.app, ticket.status]
  );

  const closeTicket = useCallback(async () => {
    ticket.status.updating = true;
    setSendingResponse(true);

    try {
      await firebaseContext.app.closeHelpdeskTicket(ticketId);
      setSendingResponse(false);
    } catch (e) {
      console.error(e);
      ticket.status.updating = false;
      setSendingResponse(false);
      setSnackOpened(true);
    }
  }, [ticketId, ticket.status, firebaseContext.app]);

  if (!ticket || sendingResponse) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress thickness={6} size={50} />
      </Grid>
    );
  }

  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <SnackError open={snackOpened} handleClose={() => setSnackOpened(false)} />

      <Button variant="contained" onClick={goBack} style={{ marginBottom: theme.spacing(2) }}>
        {'<'} Retour
      </Button>

      <Paper elevation={2} className={viewTicketClasses.detail}>
        <Typography className="" variant="h5" component="div">
          Détail
        </Typography>
        <Box display="flex" flexDirection="column">
          <Typography>
            {ticket.status.firstname} {ticket.status.lastname}
          </Typography>
          <Typography>
            Email : {ticket.status.email} - Tel: {ticket.status.phone}
          </Typography>
          <Typography>Langue: {ticket.status.lang}</Typography>
          <Typography>AAP: {ticket.status.aapId}</Typography>
          <Typography>Topic: {ticket.status.topic}</Typography>
        </Box>
      </Paper>
      <Box display="flex" alignItems="flex-start" flexDirection="column">
        {ticket.messages.map((m) => {
          return (
            <Box key={m.time} style={{ marginBottom: 10, width: '100%' }}>
              <Box>
                <Typography className={viewTicketClasses.date}>{new Date(m.time).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</Typography>
              </Box>
              <Box className={viewTicketClasses.messageContainer}>
                <Typography className={`${viewTicketClasses.message} ${m.sender === 'client' ? 'client' : ''}`}>{m.message}</Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
      {ticket.status.updating && (
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: '40px' }}>
          <CircularProgress thickness={6} size={50} />
        </Grid>
      )}
      {!ticket.status.solved && !ticket.status.updating && <TicketAnswer closeTicket={closeTicket} sendResponse={sendResponse} sendingReponse={sendingResponse} />}
    </Container>
  );
};

const TicketAnswer = (props) => {
  const [response, setResponse] = useState('');
  const [confirmOpen, setConfirmOpen] = useState(false);
  const theme = useTheme();

  const closeConfirm = (isConfirmed) => {
    setConfirmOpen(false);

    if (isConfirmed) {
      props.closeTicket();
    }
  };

  return (
    <>
      <Paper elevetion={1} style={{ marginTop: 40, padding: 10 }}>
        <Box display="flex" alignItems="center" flexDirection="column">
          <TextField
            //   disabled={props.submittingEvaluation || props.isClosedEvaluation}
            name="answer"
            onChange={(e) => setResponse(e.target.value)}
            value={response}
            //   onChange={(e) => setJustification(e.target.value)}
            //   value={justification}
            // onBlur={(e) => props.handleChange(e, parentJustif)}
            placeholder="Répondre à ce ticket"
            variant="outlined"
            multiline={true}
            rows={4}
            fullWidth={true}
            style={{ marginBottom: theme.spacing(2) }}
          />

          <Button variant="contained" disabled={!response} color="secondary" onClick={() => props.sendResponse(response)} style={{ marginBottom: theme.spacing(2) }}>
            Envoyer la réponse
          </Button>
        </Box>
      </Paper>
      <Paper elevetion={1} style={{ marginTop: 40, padding: 10, textAlign: 'center' }}>
        <Button variant="contained" color="warning" onClick={() => setConfirmOpen(true)} style={{ marginBottom: theme.spacing(2) }}>
          Clôturer le ticket
        </Button>
      </Paper>
      <ConfirmCloseDialog open={confirmOpen} onClose={closeConfirm} />
    </>
  );
};

const ConfirmCloseDialog = (props) => {
  const { onClose, open } = props;

  const handleClose = (isConfirmed) => {
    onClose(isConfirmed);
  };

  return (
    <Dialog fullWidth={true} maxWidth={'md'} className="" onClose={() => handleClose(false)} open={open}>
      <DialogTitle id="reviewers-dialog-title">
        <Box display="flex" alignItems="center">
          Êtes-vous sur de vouloir clôturer ce ticket?
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography style={{ marginBottom: 30 }}>Ni vous, ni le candidat ne pourrez continuer à répondre.</Typography>
        <Box display="flex" justifyContent="center" gap={5}>
          <Button variant="contained" color="primary" onClick={() => handleClose(true)}>
            Oui, clôturer le ticket
          </Button>

          <Button variant="contained" color="secondary" onClick={() => handleClose(false)}>
            Non, garder le ticket ouvert
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
