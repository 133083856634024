import { useState, useEffect, useContext, useCallback } from 'react';
import { FirebaseContext, UsersListContext, FirebaseDataContext } from '../../../../firebase';

import { LoadingGrid, ErrorGrid } from '../../utils';

import { downloadProjectsAttribution, downloadAllProjects } from './exportExcel';
/* Layout */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { FixedSizeList } from 'react-window';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoopIcon from '@mui/icons-material/Loop';
// import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ClassIcon from '@mui/icons-material/Class';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: 10,
    marginBottom: 30,
  },
  pageTitle: {
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: 30,
  },
  sectionTitle: {
    marginBottom: 15,
  },
}));

export const ManageThemes = (props) => {
  const classes = useStyles();

  const firebaseContext = useContext(FirebaseContext);
  const usersListContext = useContext(UsersListContext);
  const firebaseDataContext = useContext(FirebaseDataContext);

  // const [readingThemes, setReadingThemes] = useState(false);
  const [themes, setThemes] = useState([]);
  // const [errorReadingThemes, setErrorReadingThemes] = useState();

  const readingThemes = !(firebaseDataContext.themesRead || false);

  // const readThemes = useCallback(() => {
  //   setErrorReadingThemes(null);
  //   setReadingThemes(true);

  //   firebaseContext.app.listTheme().then(
  //     (themeCollection) => {
  //       const fetchedThemes = [];
  //       themeCollection.forEach((theme) => {
  //         fetchedThemes.push({ name: theme.id, ...theme.data() });
  //       });
  //       setReadingThemes(false);
  //       setThemes(fetchedThemes);
  //     },
  //     (themeError) => {
  //       console.error('error reading themes', themeError);
  //       setReadingThemes(false);
  //       setThemes([]);
  //       setErrorReadingThemes({ error: 'Une erreur est survenue, veuilez réessayer.' });
  //     }
  //   );
  // }, [firebaseContext.app]);

  // useEffect(() => {
  //   readThemes();
  // }, [readThemes]);

  useEffect(() => {
    setThemes(firebaseDataContext.themes);
  }, [firebaseDataContext.themes]);

  const refreshThemesAndUsers = () => {
    // readThemes();
    usersListContext.readUsersList();
  };

  const [addingTheme, setAddingTheme] = useState(false);
  const [addingThemeError, setAddingThemeError] = useState(null);
  const createTheme = useCallback(
    (themeName) => {
      setAddingTheme(true);
      setAddingThemeError(null);

      return new Promise((resolve, reject) => {
        if (themes.filter((theme) => theme.name === themeName).length > 0) {
          setAddingTheme(false);
          setAddingThemeError({ error: 'Cette thématique existe déjà' });
          return reject();
        }

        firebaseContext.app.addTheme(themeName).then(
          (themeCreated) => {
            // const themeStructure = themeCreated.data.newThemeStructure;
            // setThemes((prevThemes) => [...prevThemes, { name: themeName, ...themeStructure }]);

            setAddingTheme(false);
            setAddingThemeError(null);
            resolve();
          },
          (themeCreationError) => {
            console.error(themeCreationError);
            setAddingTheme(false);
            setAddingThemeError({ error: 'Une erreur est survenue' });
            reject();
          }
        );
      });
    },
    [firebaseContext.app, themes]
  );

  const [removingTheme, setRemovingTheme] = useState(false);
  const removeTheme = (themeName) => {
    setRemovingTheme(true);

    firebaseContext.app.removeTheme(themeName).then(
      (themeDeleted) => {
        setThemes((prevThemes) => prevThemes.filter((theme) => theme.name !== themeName));
        setRemovingTheme(false);
      },
      (themeDeleteError) => {
        console.error(themeDeleteError);
        setRemovingTheme(false);
      }
    );
  };

  const updateThemeAdminInTheme = (themeName, adminList) => {
    return new Promise((resolve, reject) => {
      firebaseContext.app.updateThemeAdmin(themeName, adminList).then(
        (themeAdminUpdated) => {
          setThemes((prevThemes) => {
            const newThemes = prevThemes.map((t) => {
              if (t.name !== themeName) return t;
              return { ...t, themeAdmin: adminList };
            });
            return newThemes;
          });
          resolve();
        },
        (themeAdminError) => {
          console.error(themeAdminError);
          reject();
        }
      );
    });
  };

  const setAttribution = (aapId, projectId, selectedTheme, oldTheme) => {
    const currentThemes = [...themes];

    return new Promise((resolve, reject) => {
      let removeProjectFromTheme = [];
      let isRemove = false;
      let oldThemeIndex = null,
        oldThemeProjectsBecomes = null;

      if (oldTheme) {
        oldThemeIndex = currentThemes.findIndex((theme) => theme.name === oldTheme);

        oldThemeProjectsBecomes = currentThemes[oldThemeIndex].projects.filter((project) => project !== `${projectId}`);

        isRemove = true;
        removeProjectFromTheme.push(firebaseContext.app.updateThemeProjects(oldTheme, oldThemeProjectsBecomes));
      }

      Promise.all(removeProjectFromTheme)
        .then((removed) => {
          if (isRemove) {
            currentThemes[oldThemeIndex].projects = oldThemeProjectsBecomes;
          }

          const newThemeIndex = currentThemes.findIndex((theme) => theme.name === selectedTheme);

          const newThemeProjectsBecomes = [...currentThemes[newThemeIndex].projects];
          if (newThemeProjectsBecomes.indexOf(`${projectId}`) >= 0) {
            setThemes(currentThemes);
            return resolve();
          }

          newThemeProjectsBecomes.push(`${projectId}`);

          firebaseContext.app.updateThemeProjects(selectedTheme, newThemeProjectsBecomes).then(
            (added) => {
              currentThemes[newThemeIndex].projects = newThemeProjectsBecomes;
              setThemes(currentThemes);
              return resolve();
            },
            (error) => {
              setThemes(currentThemes);
              console.error(error);
              return reject();
            }
          );
        })
        .catch((error) => {
          console.error(error);
          reject();
        });
    });
  };

  // const pp = firebaseDataContext.userProjects;
  // const ppf = Object.entries(pp).filter((p) => {
  //   return !p[1].status.submitted;
  // });
  // ppf.forEach((p) => {
  //   if (Object.keys(p[1].data || []).length > 10) {
  //     console.log(p[0], '--', Object.keys(p[1].data || []).length);
  //     console.log(p[1].data);
  //     console.log('');
  //   }
  // });

  // Get list of themeAdmin
  // const aapIDs = firebaseDataContext.aapIDs;
  const themeAdminList = usersListContext.usersList.filter((u) => u.customClaims && u.customClaims.themeAdmin);

  const readingAaps = !firebaseDataContext.definitionsRead;

  // const readingProjects = !aapIDs.map((aapId) => firebaseDataContext[`${aapId}Read`]).reduce((prev, current) => prev && current, true);
  const readingProjects = !firebaseDataContext.projectsRead;

  // const readingReviews = !aapIDs.map((aapId) => firebaseDataContext[`review_${aapId}Read`]).reduce((prev, current) => prev && current, true);
  const readingReviews = !firebaseDataContext.administrativeReviewsRead;

  if (readingThemes || usersListContext.readingUsers || readingAaps || readingProjects || readingReviews) {
    return <LoadingGrid />;
  }
  // if (errorReadingThemes) {
  //   return <ErrorGrid error={errorReadingThemes.error} refresh={readThemes} />;
  // }
  if (usersListContext.errorReadingUsers) {
    return <ErrorGrid error={usersListContext.errorReadingUsers.error} refresh={usersListContext.readUsersList} />;
  }

  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography className={classes.pageTitle} variant="h1" component="div">
            Gestion des thèmatiques
          </Typography>
        </Box>

        <Box>
          <Button variant="contained" color="primary" startIcon={<LoopIcon />} onClick={refreshThemesAndUsers}>
            Rafraichir
          </Button>
        </Box>
      </Box>

      <Paper className={classes.paper} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.sectionTitle} variant="h6" component="div">
              Thématiques
            </Typography>
            {themes.length === 0 && <p> Vous n'avez pas encore défini de thématique</p>}
            {themes.length > 0 && <ExistingThemes themes={themes} removeTheme={removeTheme} removingTheme={removingTheme} />}
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.sectionTitle} variant="h6" component="div">
              Ajouter une thématique
            </Typography>
            <AddTheme createTheme={createTheme} addingTheme={addingTheme} addingThemeError={addingThemeError} />
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper} elevation={2}>
        <Typography className={classes.sectionTitle} variant="h6" component="div">
          Gestonnaires de thématique
        </Typography>

        {themes.length === 0 && (
          <div>
            <p>Vous n'avez encore défini aucune thématique...</p>
          </div>
        )}
        {themes.length > 0 && themeAdminList.length === 0 && (
          <div>
            <p>
              Aucun utilisateur ne dispose du statut <em>themeAdmin</em>... Vous pouvez modifier les statuts dans la page <em>Utilisateurs</em>
            </p>
          </div>
        )}

        {themes.length > 0 && themeAdminList.length > 0 && (
          <Grid container spacing={2}>
            {themes.map((theme) => (
              <Grid item key={theme.name} xs={12} sm={6} md={6} lg={4}>
                <ManageThemeAdmin theme={theme} themeAdminList={themeAdminList} updateThemeAdminInTheme={updateThemeAdminInTheme} />
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>

      <Paper className={classes.paper} elevation={2}>
        <Typography className={classes.sectionTitle} variant="h6" component="div">
          Attribution des projets
        </Typography>

        {themes.length === 0 && (
          <div>
            <p>Vous n'avez encore défini aucune thématique...</p>
          </div>
        )}

        {themes.length > 0 && <ProjectsAttribution themes={themes} userList={usersListContext.usersList} setAttribution={setAttribution} />}
      </Paper>
    </Container>
  );
};

const backdropStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: '#ccc',
    backgroundColor: '#3a3838a1',
    display: 'none',
  },
  showBackdrop: {
    display: 'block',
  },
}));

const existingThemeStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    padding: 5,
    borderRadius: 5,
    overflow: 'hidden',
  },
  themeItem: {
    '&:hover': {
      backgroundColor: '#eee',
    },
  },
  deleteThemeButton: {
    padding: 0,
    backgroundColor: '#bdbdbd',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  deleteThemeButtonIcon: {
    backgroundColor: 'inherit',
  },
}));

const ExistingThemes = (props) => {
  const classes = existingThemeStyles();
  const backdropClasses = backdropStyles();

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  const handleClickOpen = (themeName) => {
    setSelectedValue(themeName);
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    if (value) {
      props.removeTheme(selectedValue);
    }
  };

  return (
    <Box className={classes.root}>
      <List>
        {props.themes.map((theme) => {
          return (
            <ListItem key={theme.name} className={classes.themeItem}>
              <ListItemAvatar>
                <IconButton className={classes.deleteThemeButton} onClick={() => handleClickOpen(theme.name)}>
                  <Avatar className={classes.deleteThemeButtonIcon}>
                    <DeleteForeverIcon />
                  </Avatar>
                </IconButton>
              </ListItemAvatar>
              <ListItemText primary={theme.name} secondary={`${theme.projects.length} project${theme.projects.length > 0 ? 's' : ''}`} />
            </ListItem>
          );
        })}
        <DeleteThemeDialog selectedValue={selectedValue} open={open} onClose={handleClose} />
      </List>
      <Container component="div" maxWidth={false} className={clsx(backdropClasses.backdrop, { [backdropClasses.showBackdrop]: props.removingTheme })}>
        <Grid style={{ height: '100%' }} container direction="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Grid>
      </Container>
    </Box>
  );
};

const deleteThemeDialogStyles = makeStyles((theme) => ({
  dialogButton: {
    margin: '0 10px',
  },
  cancelButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
}));

const DeleteThemeDialog = (props) => {
  const classes = deleteThemeDialogStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(false);
  };

  const handleConfirmation = (value) => {
    onClose(value);
  };

  return (
    <Dialog className={classes.dialogContainer} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Attention</DialogTitle>
      <DialogContent>
        <p>Supprimer une thématique supprime également l'ensemble de ses attributions (gestionnaires de thématique, candidatures et reviewers)</p>
        <p>
          Êtes-vous sur de vouloir supprimer la thématique: <em>{selectedValue}</em>
        </p>
        <Box display="flex" justifyContent="center">
          <Button
            className={classes.dialogButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              handleConfirmation(true);
            }}
          >
            Confirmer
          </Button>
          <Button
            className={clsx(classes.dialogButton, classes.cancelButton)}
            onClick={() => {
              handleConfirmation(false);
            }}
          >
            Annuler
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const addThemeStyles = makeStyles((theme) => ({
  newThemeContainer: {
    position: 'relative',
    padding: 5,
    borderRadius: 5,
    overflow: 'hidden',
  },
  error: {
    fontStyle: 'italic',
    fontWeight: 500,
    color: theme.palette.error.main,
  },
}));

const AddTheme = (props) => {
  const classes = addThemeStyles();
  const backdropClasses = backdropStyles();

  const [newThemeName, setNewThemeName] = useState('');

  const handleThemeNameChange = ({ target }) => {
    setNewThemeName(target.value);
  };

  const addTheme = () => {
    props.createTheme(newThemeName).then(
      (success) => setNewThemeName(''),
      (error) => { }
    );
  };

  return (
    <Box className={classes.newThemeContainer}>
      <Box style={{ width: '100%', maxWidth: 400, marginBottom: 10 }}>
        <TextField fullWidth label="Nom de la thématique" value={newThemeName} onChange={handleThemeNameChange} />
      </Box>
      <Button disabled={!newThemeName} variant="contained" color="secondary" onClick={addTheme}>
        Créer la thématique
      </Button>

      {props.addingThemeError && (
        <Box className={classes.error}>
          <p>{props.addingThemeError.error}</p>
        </Box>
      )}

      <Container component="div" maxWidth={false} className={clsx(backdropClasses.backdrop, { [backdropClasses.showBackdrop]: props.addingTheme })}>
        <Grid style={{ height: '100%' }} container direction="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Grid>
      </Container>
    </Box>
  );
};

const manageThemeAdminStyles = makeStyles((theme) => ({
  manageThemeAdminPaperContainer: {
    position: 'relative',
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: 10,
  },
  themeTitle: {
    marginBottom: 15,
  },
  formControl: {
    width: '100%',
    maxWidth: 'min(500px, calc(100% - 48px))',
  },
  deleteAdminButton: {
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: 'white',
    },
  },
  deleteAdminButtonIcon: {
    width: 20,
    height: 20,
  },
  noThemeAdminContainer: {
    marginTop: 10,
    fontSize: '.8rem',
    color: theme.palette.error.main,
  },
  addThemeAdminButton: {
    marginTop: 5,
  },
}));

const ManageThemeAdmin = (props) => {
  const classes = manageThemeAdminStyles();
  const backdropClasses = backdropStyles();

  const [editingThemeAdmin, setEditingThemeAdmin] = useState(false);
  const [selectedAddThemeAdmin, setSelectedAddThemeAdmin] = useState('');

  const handleSelectThemeAdminChange = ({ target }) => {
    setSelectedAddThemeAdmin(target.value);
  };

  const addThemeAdmin = () => {
    const currentSelected = selectedAddThemeAdmin;

    setEditingThemeAdmin(true);
    setSelectedAddThemeAdmin('');
    const newAdminList = [...props.theme.themeAdmin];
    newAdminList.push(selectedAddThemeAdmin);

    props.updateThemeAdminInTheme(props.theme.name, newAdminList).then(
      (success) => {
        setEditingThemeAdmin(false);
      },
      (error) => {
        setSelectedAddThemeAdmin(currentSelected);
        setEditingThemeAdmin(false);
      }
    );
  };

  const removeThemeAdminFromTheme = (adminUid) => {
    const newAdminList = props.theme.themeAdmin.filter((u) => u !== adminUid);

    setEditingThemeAdmin(true);
    props.updateThemeAdminInTheme(props.theme.name, newAdminList).then(
      (success) => {
        setEditingThemeAdmin(false);
      },
      (error) => {
        setEditingThemeAdmin(false);
      }
    );
  };

  const availableThemeAdmin = props.themeAdminList.filter((ta) => props.theme.themeAdmin.indexOf(ta.uid) < 0);
  const currentThemeAdmin = props.themeAdminList.filter((ta) => props.theme.themeAdmin.indexOf(ta.uid) >= 0).map((ta) => ta.uid);

  return (
    <Paper elevation={2} className={classes.manageThemeAdminPaperContainer}>
      <Container component="div" maxWidth={false} className={classes.manageThemeAdminContainer}>
        <Typography className={classes.themeTitle} variant="h6" component="div">
          {props.theme.name}
        </Typography>

        {availableThemeAdmin.length > 0 && (
          <Box display="flex" alignItems="center" flexDirection="column">
            <Box flexGrow={1} style={{ width: '80%', maxWidth: 500 }} display="flex" justifyContent="center">
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="select-outlined-label">Ajouter un gestionnaire</InputLabel>
                <Select labelId="select-outlined-label" id="select-outlined" value={selectedAddThemeAdmin} onChange={handleSelectThemeAdminChange} label="Ajouter un gestionnaire">
                  <MenuItem value="">&nbsp;</MenuItem>
                  {availableThemeAdmin.map((admin) => (
                    <MenuItem key={admin.uid} value={admin.uid}>
                      {admin.email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box>
              {selectedAddThemeAdmin && (
                <>
                  {/* <IconButton color="secondary" className={classes.addThemeAdminButton} onClick={addThemeAdmin}>
                   <CheckIcon />
                 </IconButton> */}
                  <Button variant="contained" color="secondary" className={classes.addThemeAdminButton} onClick={addThemeAdmin}>
                    Ajouter
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
        {currentThemeAdmin.length === 0 && (
          <Container component="div" maxWidth={false}>
            <Box display="flex" alignItems="center" className={classes.noThemeAdminContainer}>
              <Box style={{ marginRight: 5 }}>
                <SentimentVeryDissatisfiedIcon />
              </Box>
              <Box>Personne ne peut administrer cette thématique</Box>
            </Box>
          </Container>
        )}
        {currentThemeAdmin.length > 0 && (
          <List>
            {currentThemeAdmin.map((adminUid) => {
              const themeAdminUser = props.themeAdminList.filter((u) => u.uid === adminUid)[0];
              if (!themeAdminUser) return <div key={`${props.theme.name}_${adminUid}`}></div>;
              return (
                <ListItem key={`${props.theme.name}_${adminUid}`} className={classes.themeItem}>
                  <IconButton size="small" className={classes.deleteAdminButton} onClick={() => removeThemeAdminFromTheme(adminUid)}>
                    <DeleteForeverIcon className={classes.deleteAdminButtonIcon} />
                  </IconButton>
                  <ListItemText primaryTypographyProps={{ style: { fontSize: '0.9rem' } }} primary={themeAdminUser.email} />
                </ListItem>
              );
            })}
          </List>
        )}
      </Container>
      <Container component="div" maxWidth={false} className={clsx(backdropClasses.backdrop, { [backdropClasses.showBackdrop]: editingThemeAdmin })}>
        <Grid style={{ height: '100%' }} container direction="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Grid>
      </Container>
    </Paper>
  );
};

const projectAttributionStyles = makeStyles((theme) => ({
  attibutionProjectContainer: {
    position: 'relative',
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: 30,
  },
  notYetAttributedContainer: {},
  donwloadProjectAttributionContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  themeTitle: {
    marginBottom: 20,
    fontSize: '1.1rem',
    color: theme.palette.warning.main,
  },
  themeTitleAttributed: {
    marginBottom: 20,
    marginTop: 40,
    fontSize: '1.1rem',
    color: theme.palette.secondary.main,
  },
  specificTitle: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
    fontWeight: 500,
  },
  root: {
    width: '100%',
    height: 400,
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  fixedList: {
    '& > div': {
      boxSizing: 'border-box',
      border: '1px solid #ccc',
      borderRadius: 5,
      marginTop: 1,

      '& li:first-child': {
        borderTop: 'none',
      },
    },
  },
}));

const ProjectsAttribution = (props) => {
  const classes = projectAttributionStyles();
  const firebaseDataContext = useContext(FirebaseDataContext);
  // const firebaseContext = useContext(FirebaseContext);
  const usersListContext = useContext(UsersListContext);

  const [attributionDialogOpen, setAttributionDialogOpen] = useState(false);
  const [attributionProjectData, setAttributionProjectData] = useState({});

  const [exportingFile, setExportingFile] = useState(false);

  const openAttributionDialog = (aapId, projectId, projectData) => {
    setAttributionProjectData({
      aapId,
      projectId,
      projectData,
      aapName: firebaseDataContext.definitions[aapId].name_fr,
    });
    setAttributionDialogOpen(true);
  };

  const closeAttributionDialog = () => {
    setAttributionDialogOpen(false);
    setAttributionProjectData({});
  };

  const attributedProjects = props.themes
    .map((theme) => theme.projects || [])
    .flat()
    .filter((projectID) => firebaseDataContext.userProjects.hasOwnProperty(projectID));

  // Find project to Attribute
  const projectsToAttribute = [];

  const toAttributeProjectIds = (Object.keys(firebaseDataContext.userProjects) || [])
    .filter((pId) => {
      return firebaseDataContext.userProjects[pId].status.project_number !== undefined && attributedProjects.indexOf(pId) < 0;
    })
    .sort((p1, p2) => {
      const aap_p1 = firebaseDataContext.userProjects[p1].status.aap_id;
      const aap_p2 = firebaseDataContext.userProjects[p2].status.aap_id;

      const pn1 = firebaseDataContext.userProjects[p1].status.project_number || 0;
      const pn2 = firebaseDataContext.userProjects[p2].status.project_number || 0;

      if (aap_p1 === aap_p2) {
        if (pn1 < pn2) return -1;
        if (pn1 > pn2) return 1;
        return 0;
      }

      return aap_p1 < aap_p2 ? -1 : 1;
    });


  let currentAapName = undefined;
  toAttributeProjectIds.forEach((pId) => {
    const aapName = firebaseDataContext.userProjects[pId].status.aap_id;

    if (currentAapName !== aapName) {
      projectsToAttribute.push({ type: 'header', categoryId: aapName });
      currentAapName = aapName;
    }
    projectsToAttribute.push({ type: 'entry', categoryId: aapName, projectId: pId });
  });

  const getItemKey = (index, data) => {
    const item = data.projects[index];
    const prefix = data.listName;

    if (item.type === 'header') return `${prefix}_header_${item.categoryId}`;
    else if (item.type === 'entry') return `${prefix}_entry_${item.categoryId}_${item.projectId}`;

    // fallback, this should not happen
    return index;
  };

  const downloadExcelProjectsAttribution = () => {
    setExportingFile(true);

    const evaluationFlag = firebaseDataContext.evaluation_flag;
    const evaluationQuestions = (firebaseDataContext.evaluationDefinitions[evaluationFlag] || {}).evaluationQuestions || [];

    const administrativeReviews = firebaseDataContext.administrativeReviews || {};
    const evaluationsGlobales = firebaseDataContext.evaluationsGlobales || {};

    firebaseDataContext.getEvaluationOfReviewers(props.userList).then((all_evaluations) => {
      downloadProjectsAttribution(
        'Attribution des thématiques',
        'suivi_thematiques.xlsx',
        props.userList,
        all_evaluations,
        props.themes,
        firebaseDataContext.userProjects,
        firebaseDataContext.definitions,
        evaluationQuestions,
        administrativeReviews,
        evaluationsGlobales
      ).then(() => {
        setExportingFile(false);
      });
    });
  };

  const downloadExcelAllProjects = () => {
    setExportingFile(true);

    downloadAllProjects(props.userList, firebaseDataContext.userProjects).then(() => {
      setExportingFile(false);
    });
  };

  return (
    <>
      <Box className={classes.donwloadProjectAttributionContainer}>
        <Button disabled={exportingFile} variant="outlined" color="primary" onClick={downloadExcelProjectsAttribution}>
          Télécharger le fichier d'attribution
        </Button>

        {false && (
          <Button disabled={exportingFile} variant="outlined" color="primary" onClick={downloadExcelAllProjects}>
            Télécharger la liste de tous les projets
          </Button>
        )}
      </Box>

      {projectsToAttribute.length > 0 && (
        <Paper elevation={2} className={clsx(classes.attibutionProjectContainer, classes.notYetAttributedContainer)}>
          <Container component="div" maxWidth={false} className={classes.manageThemeAdminContainer}>
            <Typography className={classes.themeTitle} variant="h6" component="div">
              Projet à attribuer
            </Typography>

            <div className={classes.root}>
              <FixedSizeList
                height={400}
                width="100%"
                itemSize={60}
                itemCount={projectsToAttribute.length}
                itemData={{ listName: 'un', projects: projectsToAttribute, openAttributionDialog: openAttributionDialog }}
                itemKey={getItemKey}
                className={classes.fixedList}
              >
                {RenderRow}
              </FixedSizeList>
            </div>
          </Container>
        </Paper>
      )}

      <Typography className={classes.themeTitleAttributed} variant="h6" component="div">
        Projet déjà attribué
      </Typography>
      {props.themes.map((theme) => {
        // sort project by aap_id (without mutation, so using concat)
        const sortedProjectInTheme = theme.projects.concat().sort((p1, p2) => {
          const aap_p1 = ((firebaseDataContext.userProjects[p1] || {}).status || {}).aap_id;
          const aap_p2 = ((firebaseDataContext.userProjects[p2] || {}).status || {}).aap_id;

          const pn1 = ((firebaseDataContext.userProjects[p1] || {}).status || {}).project_number || 0;
          const pn2 = ((firebaseDataContext.userProjects[p2] || {}).status || {}).project_number || 0;

          if (aap_p1 === aap_p2) {
            if (pn1 < pn2) return -1;
            if (pn1 > pn2) return 1;
            return 0;
          }
          return aap_p1 < aap_p2 ? -1 : 1;
        });

        const projectsAlreadyAttributed = [];

        let currentAapName = undefined;
        sortedProjectInTheme.forEach((pId) => {
          if (!(pId in firebaseDataContext.userProjects)) return;

          const aapName = ((firebaseDataContext.userProjects[pId] || {}).status || {}).aap_id;

          if (currentAapName !== aapName) {
            projectsAlreadyAttributed.push({ type: 'header', categoryId: aapName });
            currentAapName = aapName;
          }
          projectsAlreadyAttributed.push({ type: 'entry', categoryId: aapName, projectId: pId });
        });

        return (
          <Paper key={`attributed_${theme.name.replace(' ', '')}`} elevation={2} className={classes.attibutionProjectContainer}>
            <Container component="div" maxWidth={false} className={classes.manageThemeAdminContainer}>
              <Typography className={clsx(classes.themeTitle, classes.specificTitle)} variant="h6" component="div">
                Thematique : {theme.name}
              </Typography>
              {projectsAlreadyAttributed.length > 0 && (
                <div className={classes.root}>
                  <FixedSizeList
                    height={400}
                    width="100%"
                    itemSize={60}
                    itemCount={projectsAlreadyAttributed.length}
                    itemData={{ listName: theme.name.replace(' ', ''), projects: projectsAlreadyAttributed, openAttributionDialog: openAttributionDialog }}
                    itemKey={getItemKey}
                  >
                    {RenderRow}
                  </FixedSizeList>
                </div>
              )}
              {projectsAlreadyAttributed.length <= 0 && <p>Il n'y aucun projet dans cette thématique...</p>}
            </Container>
          </Paper>
        );
      })}

      <SetAttributionDialog
        setAttribution={props.setAttribution}
        attributionProjectData={attributionProjectData}
        themes={props.themes}
        usersList={usersListContext.usersList}
        open={attributionDialogOpen}
        handleClose={closeAttributionDialog}
      />
    </>
  );
};

const renderRowStyles = makeStyles((theme) => ({
  categoryHeader: {
    // fontWeight:500,
    fontSize: '1rem',
    border: '1px solid',
    // borderBottom: '1px dashed',
    borderColor: '#ccc',
    backgroundColor: '#eee',
    borderRadius: 5,
  },
}));

const RenderRow = (props) => {
  const classes = renderRowStyles();
  const firebaseDataContext = useContext(FirebaseDataContext);
  const { index, style } = props;

  const project = props.data.projects[props.index];

  const openAttributionModal = () => {
    props.data.openAttributionDialog(project.categoryId, project.projectId, firebaseDataContext.userProjects[project.projectId]);
  };

  if (project.type === 'header') {
    const categoryName = firebaseDataContext.definitions[project.categoryId].name_fr;
    return (
      <>
        {/* <ListSubheader style={style} className={classes.categoryHeader}>
          <ListItemIcon>
            <ClassIcon />
          </ListItemIcon>
          <ListItemText primary={categoryName} />
        </ListSubheader> */}
        <ListItem style={style} className={classes.categoryHeader}>
          <ListItemIcon>
            <ClassIcon />
          </ListItemIcon>
          <ListItemText primary={categoryName} />
        </ListItem>
      </>
    );
  }

  const projectData = firebaseDataContext.userProjects[project.projectId];

  const projectNumber = ((projectData || {}).status || {}).project_number || 0;
  const acronyme = ((projectData || {}).data || {}).acronyme || '???';
  const identity = ((projectData || {}).data || {}).identity || '???';
  return (
    <ListItem button style={style} key={index} onClick={openAttributionModal}>
      <ListItemText primary={`#${projectNumber} - ${acronyme}`} secondary={`par ${identity}`} />
    </ListItem>
  );
};

const setAttributionDialogStyles = makeStyles((theme) => ({
  projectName: {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '5px 0',
  },
  projectBy: {
    fontSize: '.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '5px 0',
    marginBottom: 10,
  },
  headInfo: {
    fontSize: '.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '5px 0',
  },

  projectObjectif: {
    marginTop: 10,
    fontSize: '.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid',
    borderRadius: 10,
    padding: 8,
    maxHeight: 'min(30vh, 300px)',
    overflowY: 'auto',
  },
  formContainer: {
    marginTop: 30,
    marginBottom: 10,
  },
  formControl: {
    width: '100%',
    maxWidth: 'min(500px, calc(100% - 48px))',
  },
  settingThemeError: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '.8rem',
    color: theme.palette.error.main,
  },
}));

const SetAttributionDialog = (props) => {
  const classes = setAttributionDialogStyles();
  const backdropClasses = backdropStyles();

  const { setAttribution, open, handleClose } = props;
  const { attributionProjectData } = props;

  const projectData = attributionProjectData.projectData || { data: {}, status: {} };

  const inTheme = (
    props.themes.filter((theme) => {
      return theme.projects.indexOf(`${attributionProjectData.projectId}`) >= 0;
    })[0] || {}
  ).name;

  const userManagingProject = props.usersList.filter((u) => u.uid === projectData.status.uid)[0] || {};

  const [selectedTheme, setSelectedTheme] = useState('');

  useEffect(() => {
    if (inTheme && selectedTheme === '') {
      setSelectedTheme(inTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inTheme]);

  const handleSelectThemeChange = ({ target }) => {
    setSelectedTheme(target.value);
  };

  const closeDialog = () => {
    setSelectedTheme('');
    setSettingThemeForProjectError(null);
    handleClose();
  };

  const [settingThemeForProject, setSettingThemeForProject] = useState(false);
  const [settingThemeForProjectError, setSettingThemeForProjectError] = useState(null);
  const setThemeForProject = () => {
    setSettingThemeForProject(true);
    setSettingThemeForProjectError(null);

    setAttribution(attributionProjectData.aapId, attributionProjectData.projectId, selectedTheme, inTheme).then(
      (themeSet) => {
        setSettingThemeForProject(false);
        setSettingThemeForProjectError(null);
        closeDialog();
      },
      (themeError) => {
        setSettingThemeForProject(false);
        setSettingThemeForProjectError('Une erreur est survenue, veuillez réessayer');
      }
    );
  };

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={closeDialog}>
      <DialogTitle id="simple-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flex={1}>Attribution du projet</Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={closeDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {projectData && (
          <>
            <div className={classes.projectName}>{projectData.data.acronyme || '???'}</div>
            <div className={classes.projectBy}>
              par <strong>{projectData.data.identity || '???'}</strong>
            </div>
            <div className={classes.projectBy}>
              email: <strong>{userManagingProject.email}</strong>
            </div>

            <div className={classes.headInfo}>
              Catégorie: <strong>{attributionProjectData.aapName}</strong>
            </div>
            <div className={classes.headInfo}>
              ID Project: <strong>{attributionProjectData.projectId}</strong>
            </div>

            <div className={classes.projectObjectif}>{projectData.data.description || '???'}</div>
          </>
        )}
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" className={classes.formContainer}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="select-theme-label">Sélectionner une thématique</InputLabel>
            <Select labelId="select-theme-label" id="select-theme" value={selectedTheme} onChange={handleSelectThemeChange} label="Sélectionner une thématique">
              {props.themes.map((theme) => (
                <MenuItem key={theme.name.replace(' ', '')} value={theme.name}>
                  {theme.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button variant="contained" disabled={inTheme === selectedTheme || selectedTheme === ''} color="secondary" style={{ marginTop: 10 }} onClick={setThemeForProject}>
            Valider
          </Button>

          {settingThemeForProjectError && <p className={classes.settingThemeError}>{settingThemeForProjectError}</p>}
        </Box>

        <Container component="div" maxWidth={false} className={clsx(backdropClasses.backdrop, { [backdropClasses.showBackdrop]: settingThemeForProject })}>
          <Grid style={{ height: '100%' }} container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
