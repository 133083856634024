import { useContext, useEffect, useState, useMemo, useRef, forwardRef, useCallback } from 'react';

// import { useParams, useHistory } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router-dom';

// import { downloadPDF } from './downloadPDF';

/* ROUTES */
import * as routes from '../../../routing/routes';

/* CONTEXT */
import { FirebaseContext, FirebaseDataContext } from '../../../firebase';

import { FilesAccordion } from '../../utilities/filesAccordion';

/* LAYOUT */
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import TrafficIcon from '@mui/icons-material/Traffic';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  applicationContainer: {
    paddingTop: 20,
    paddingBottom: 50,

    '&.submitting': {
      pointerEvents: 'none',
    },
  },

  applicationHeader: {},
  categoryName: {
    fontStyle: 'italic',
    marginBottom: 20,
  },
  checkHeader: {
    marginBottom: 10,
  },
  checkContent: {
    paddingTop: 10,
    paddingBottom: 10,
    marginBottom: 30,
    border: '1px solid #666',
    borderColor: theme.palette.secondary.main,
    borderRadius: 5,

    '&.notEditable ': {
      borderColor: '#666!important',
    },

    '&.notSubmitted': {
      borderWidth: 2,
      borderColor: theme.palette.error.main,
    },
  },
  submitContainer: {
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    verticalAlign: 'middle',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  inlineElement: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: 15,
  },
  saveContainer: {
    position: 'sticky',
    bottom: 0,
    background: 'white',
    // width: '100%',
    textAlign: 'center',

    transform: 'scaleY(0)',
    transformOrigin: 'bottom',
    transition: 'transform 200ms ease-in-out',
    pointerEvents: 'none',
    height: 0,
    padding: 0,
  },
  showSave: {
    height: 'auto',
    padding: 50,
    transform: 'scaleY(1)',
    pointerEvents: 'all',
    borderTop: '1px solid ' + theme.palette.secondary.main,
  },
  closeContainer: {
    textAlign: 'center',
    height: 'auto',
    padding: 30,
  },
  commentBox: {
    display: 'none',
    // transform: 'scaleY(0)',
    // transformOrigin: 'top',
    // transition: 'transform 200ms ease-in-out',
  },
  showCommentBox: {
    // transform: 'scaleY(1)',
    display: 'block',
  },
  closeButton: {
    color: 'white',
    '&.button-accepted': {
      backgroundColor: theme.palette.secondary.main,
    },
    '&.button-rejected': {
      backgroundColor: theme.palette.error.main,
    },
    '&.button-NYD': {
      backgroundColor: theme.palette.info.main,
    },
    '&.button-warning': {
      backgroundColor: theme.palette.warning.main,
    },
    '&.button-waiting': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  notEditableMessage: {
    padding: 20,
    fontWeight: 500,
    borderWidth: 2,
    borderColor: theme.palette.warning.main,
    borderStyle: 'solid',
    borderRadius: 10,
    marginBottom: 20,
    '& p': {
      fontWeight: 500,
    },
  },
}));

const defaultReview = {
  // filled: '',
};

export const AdminSpec = ({ containerRef }) => {
  // const history = useHistory();
  const navigate = useNavigate();
  const classes = useStyles();

  const firebaseDataContext = useContext(FirebaseDataContext);
  const firebaseContext = useContext(FirebaseContext);
  const { projectId } = useParams();

  const readingData = !firebaseDataContext.administrativeReviewsRead || !firebaseDataContext.projectsRead || !firebaseDataContext.evaluation_flagRead;

  const [reviewState, setReviewState] = useState(defaultReview);
  const [reviewStateApplied, setReviewStateApplied] = useState(false);

  const [submittingReview, setSubmittingReview] = useState(false);

  const [reviewEditable, setReviewEditable] = useState(true);

  const [openSnack, setOpenSnack] = useState(false);
  // const[snackMessage, setSnackMessage] = useState("")

  useEffect(() => {
    // ensure data has been read
    if (!firebaseDataContext.projectsRead || !firebaseDataContext.definitionsRead || !firebaseDataContext.administrativeReviewsRead) {
      navigate(routes.ADMIN, { replace: true });
      return;
    }

    // ensure project id is correct
    if (!(projectId in firebaseDataContext.userProjects)) {
      navigate(routes.ADMIN, { replace: true });
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSnackOpen = useCallback(() => {
    setOpenSnack(true);
  }, []);

  const handleSnackClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnack(false);
  }, []);

  const existingReview = useMemo(() => (firebaseDataContext.administrativeReviews && firebaseDataContext.administrativeReviews[projectId]) || {}, [firebaseDataContext.administrativeReviews, projectId]);
  const currentProject = useMemo(() => (firebaseDataContext.userProjects && firebaseDataContext.userProjects[projectId]) || { status: {} }, [firebaseDataContext.userProjects, projectId]);

  useEffect(() => {
    if (existingReview.status === 'reviewed' && existingReview.result !== 'waiting' && existingReview.flag === firebaseDataContext.evaluation_flag) setReviewEditable(false);
    if (!currentProject.status.submitted) setReviewEditable(false);
  }, [existingReview, currentProject, firebaseDataContext.evaluation_flag]);

  /* initialized reviews */
  const [currentReview, setCurrentReview] = useState({});
  useEffect(() => {
    if (firebaseDataContext.administrativeReviewsRead) {
      setCurrentReview({
        ...defaultReview,
        ...firebaseDataContext.administrativeReviews[projectId],
      });

      setReviewState({
        ...defaultReview,
        ...firebaseDataContext.administrativeReviews[projectId],
      });

      setReviewStateApplied(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseDataContext.administrativeReviewsRead]);

  /* Early return when not yet read */
  if (readingData) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100%' }}>
        <CircularProgress thickness={6} size={50} />
      </Grid>
    );
  }

  // const aapData = firebaseDataContext.definitions[aapId];
  const projectData = firebaseDataContext.userProjects[projectId];

  const evaluationFlag = firebaseDataContext.evaluation_flag;
  const administrativeQuestions = (firebaseDataContext.evaluationDefinitions[evaluationFlag] || {}).administrativeQuestions || [];
  const administrativeParameters = (firebaseDataContext.evaluationDefinitions[evaluationFlag] || {}).adminstrativeReviewParameters || {};

  /* form change events */
  const handleChange = ({ target }, parent) => {
    const { value, name } = target;
    setReviewState((prevState) => {
      return { ...prevState, [name]: value };
    });

    if (target.tagName === 'TEXTAREA' && parent) {
      // window.scrollTo(target);
      const parentBBox = parent.current.getBoundingClientRect();

      const topLimit = 100;
      if (parentBBox.top !== topLimit) {
        containerRef.current.scrollBy(0, parentBBox.top - topLimit);
      }
    }
  };

  const stateEqualCurrentReview = () => {
    for (let k in reviewState) {
      if (!(k in currentReview)) return false;
      if (reviewState[k] !== currentReview[k]) return false;
    }
    return true;
  };

  const submitReview = () => {
    setSubmittingReview(true);

    firebaseContext.app.setAdministrativeReview(projectId, { ...reviewState, flag: evaluationFlag, status: 'in-review' }).then(
      (written) => {
        setCurrentReview({
          ...reviewState,
        });
        setSubmittingReview(false);
      },
      (error) => {
        console.error(error);
        setSubmittingReview(false);
        handleSnackOpen();
      }
    );
  };

  const closingReview = (sub) => {
    let closingFunction;
    if (sub.email) {
      closingFunction = firebaseContext.app.closeAdmnistrativeReviewWithEmail;
    } else {
      closingFunction = firebaseContext.app.closeAdministrativeReview;
    }

    setSubmittingReview(true);

    closingFunction(projectId, sub).then(
      (closingResult) => {
        // history.replace(routes.ADMIN);
        // setSubmittingReview(false);
        navigate(`${routes.ADMIN}${routes.ADMINISTRATIVE_REVIEW}`, { replace: true });
      },
      (closingError) => {
        console.error('closing error', closingError);
        setSubmittingReview(false);
        handleSnackOpen();
      }
    );
  };

  const allRequiredAreFilled = () => {
    // for (let f of requiredFields) {
    //   if (!(f in currentReview)) return false;
    //   if (typeof currentReview[f] !== 'boolean') return false;
    // }
    // return true;

    // check all question are filled

    for (const sec of administrativeQuestions) {
      for (const q of sec.questions || []) {
        if (q.conditional && q.conditional.field && q.conditional.value) {
          const projectEntry = q.conditional.status_field ? projectData.status : projectData.data;
          if (!q.conditional.value.includes(projectEntry[q.conditional.field])) {
            // conditional question are not required
            continue;
          }
        }
        if (!(q.id in currentReview)) return false;
        if (currentReview[q.id] === undefined) return false;
      }
    }
    return true;
  };

  const reviewResults = (administrativeParameters || {}).results || [
    {
      value: 'rejected',
      color: 'error',
    },
    {
      value: 'warning',
      color: 'warning',
    },
    {
      value: 'accepted',
      color: 'secondary',
    },
  ];

  // const lastMessageSend = reviewState.lastMessageSend ? reviewState.lastMessageSend.replace(/\n/g, '<br/>') : null;

  return (
    <Container component="div" maxWidth={false} className={classes.applicationContainer + (submittingReview ? ' submitting' : '')}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openSnack} autoHideDuration={4000} onClose={handleSnackClose}>
        <Alert onClose={handleSnackClose} severity="error" sx={{ width: '100%' }}>
          Une erreur est survenue !!
        </Alert>
      </Snackbar>

      <Typography variant="h4" className={classes.applicationHeader}>
        Soumis par {projectData.data && projectData.data.identity}
      </Typography>
      <Typography variant="h6" className={classes.categoryName}>
        {firebaseDataContext.definitions[projectData.status.aap_id].name_fr}
      </Typography>

      {!reviewEditable && (
        <>
          <Box className={classes.notEditableMessage}>
            <Typography>L'analyse a déjà été enregistrée, vous ne pouvez plus la modifier</Typography>
            <Box sx={{ p: 2 }}>
              <Typography>Résultat: </Typography>
              <TrafficIcon
                color={
                  (reviewResults.filter((v) => v.value === currentReview.result)[0] || {}).color || 'primary'
                  // currentReview.result === -1 ? 'error' : currentReview.result === 0 ? 'warning' : 'secondary'
                }
                sx={{ fontSize: 40, verticalAlign: 'middle' }}
              />
            </Box>
            <Box sx={{ p: 2, pt: 0 }}>
              <Typography>Message:</Typography>
              <TextField disabled name="message" value={currentReview.message} variant="outlined" multiline={true} fullWidth={true} />
            </Box>
          </Box>
        </>
      )}

      <FilesAccordion projectId={projectId} />

      {
        // iterate on each section of administrative question
        administrativeQuestions.map((adminSection, sec_idx) => {
          return (
            <Paper key={`admin_sec_${sec_idx}`} sx={{ pt: 2, pb: 1, mb: 3 }}>
              <Container component="div" maxWidth={false}>
                <Typography variant="h5" className={classes.checkHeader} dangerouslySetInnerHTML={{ __html: `${sec_idx + 1}. ${adminSection.title}` }}></Typography>

                {(adminSection.questions || []).map((Q, q_idx) => {
                  let hidden = false;
                  if (Q.conditional && Q.conditional.field && Q.conditional.value) {
                    const projectEntry = Q.conditional.status_field ? projectData.status : projectData.data;
                    if (!Q.conditional.value.includes(projectEntry[Q.conditional.field] || '')) {
                      hidden = true;
                    }
                  }
                  return (
                    <SimpleQuestion
                      key={`admin_${sec_idx}question_${q_idx}`}
                      reviewEditable={reviewEditable}
                      // text={Q.text}
                      question={Q.text}
                      reviewState={reviewState}
                      name={Q.id}
                      handleChange={handleChange}
                      hidden={hidden}
                    />
                  );
                })}
              </Container>
            </Paper>
          );
        })
      }

      {reviewEditable && allRequiredAreFilled() && stateEqualCurrentReview() && (
        <SubmissionContent
          currentResult={currentReview.result ?? null}
          currentMessage={currentReview.message ?? null}
          submittingReview={submittingReview}
          lang={projectData.status.lang}
          submitClosing={closingReview}
          administrativeParameters={administrativeParameters}
          reviewResults={reviewResults}
        />
      )}

      {reviewStateApplied && reviewEditable && (
        <div className={classes.saveContainer + ' ' + (stateEqualCurrentReview() ? '' : classes.showSave)}>
          <Button variant="contained" disabled={submittingReview} color="secondary" onClick={submitReview}>
            {!submittingReview ? 'Sauvegarder' : <CircularProgress thickness={4} size={24} />}
          </Button>
          <Typography component="p" style={{ marginTop: 10, display: 'block', fontSize: '.8rem' }}>
            Vous pouvez sauvegarder votre analyse à tout moment.<br></br>
            Vous pourrez cloturer la candidature une fois que toutes les réponses seront enregistrées<br></br>
            <strong>Attention:</strong> La cloture d'une candidature est définitive
          </Typography>
        </div>
      )}
    </Container>
  );
};

const SubmissionContent = (props) => {
  const classes = useStyles();

  const theme = useTheme();

  const [submission, setSubmission] = useState({
    result: '',
    message: '',
    email: '',
    // subject: '',
  });

  useEffect(() => {
    if (props.currentMessage !== null) {
      setSubmission((prev) => ({
        ...prev,
        message: props.currentMessage,
      }));
    }
    if (props.currentResult !== null) {
      setSubmission((prev) => ({
        ...prev,
        result: props.currentResult,
      }));
    }
  }, [props.currentMessage, props.currentResult]);

  const closeReview = () => {
    props.submitClosing({
      ...submission,
      status: 'reviewed',
      // status: submission.result === 'NYD' ? 'pending' : 'reviewed',
    });
  };

  const handleChangeSubmission = ({ target }) => {
    const { name, value } = target;

    setSubmission((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const valueMapping = {
    accepted: 'Accepté',
    rejected: 'Non recevable',
    warning: 'Attention',
    waiting: 'Ré-ouvrir le projet',
  };

  const shouldSendEmail = props.administrativeParameters.sendEmail && (props.administrativeParameters.sendEmail || []).includes(submission.result);

  return (
    <Container component="div" maxWidth={false} className={classes.checkContent + ' ' + classes.submitContainer}>
      <Box>
        <Typography variant="h6" style={{ marginBottom: 10 }}>
          Cloturer la candidature
        </Typography>

        <p className={classes.inlineElement}>Quel statût voulez-vous donner à la candidature</p>
        {/* <FormControl variant="outlined" className={classes.formControl}>
          <Select value={submission.result} name="result" onChange={handleChangeSubmission} inputProps={{ 'aria-label': 'Without label' }}>
            <MenuItem value="accepted">Accepté</MenuItem>
            <MenuItem value="rejected">Non recevable</MenuItem>
            <MenuItem value="NYD">En attente (ré-ouvre le projet du soumissionnaire)</MenuItem>
          </Select>
        </FormControl> */}

        <Grid container spacing={2}>
          {props.reviewResults.map((v) => {
            // const color = v === -1 ? 'error' : v === 0 ? 'warning' : 'secondary';
            const color = v.color;

            return (
              <Grid key={`traffic${v.value}`} item xs={4} sx={{ textAlign: 'center' }}>
                <Typography sx={{ textAlign: 'center', fontSize: '10px', fontWeight: 'bold' }} color={theme.palette[color]?.main}>
                  {valueMapping[v.value]}
                </Typography>
                <IconButton
                  style={{ borderWidth: submission.result === v.value ? 2 : 0, borderColor: theme.palette[color].main, borderStyle: 'solid' }}
                  sx={{ p: 2 }}
                  onClick={() =>
                    setSubmission((prev) => {
                      const shouldUpdateEmail = props.administrativeParameters.sendEmail && (props.administrativeParameters.sendEmail || []).includes(v.value);
                      let email = '';
                      if (shouldUpdateEmail) {
                        email = ((props.administrativeParameters.emailTemplates || {})[v.value] || {})[props.lang];
                      }
                      return { ...prev, result: v.value, email: email };
                    })
                  }
                >
                  <TrafficIcon color={color} sx={{ fontSize: 40 }} />
                </IconButton>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Box className={classes.commentBox + ' ' + (submission.result !== '' ? classes.showCommentBox : '')}>
        <p> Ce message sera affiché pour le gestionnaire thématique en charge du projet</p>
        {/* <p> Ce message sera envoyé au soumissionaire (vous pouvez le modifier)</p> */}
        {/* <p>
          Sujet: <em>{submission.subject}</em>
        </p> */}
        <TextField
          name="message"
          onChange={handleChangeSubmission}
          value={submission.message}
          placeholder="Insérer un message à envoyer au gestionnaire thématique"
          variant="outlined"
          multiline={true}
          rows={8}
          fullWidth={true}
        />
      </Box>

      {shouldSendEmail && (
        <Box className={classes.commentBox + ' ' + (submission.result !== '' ? classes.showCommentBox : '')}>
          <p> Ce message sera envoyé par email au porteur de projet</p>
          {/* <p> Ce message sera envoyé au soumissionaire (vous pouvez le modifier)</p> */}
          {/* <p>
          Sujet: <em>{submission.subject}</em>
        </p> */}
          <TextField
            name="email"
            onChange={handleChangeSubmission}
            value={submission.email}
            placeholder="Insérer un message à envoyer au porteur de projet"
            variant="outlined"
            multiline={true}
            rows={8}
            fullWidth={true}
          />
        </Box>
      )}

      <div className={classes.closeContainer}>
        <Button
          variant="contained"
          className={`${classes.closeButton} button-${submission.result}`}
          disabled={props.submittingReview || submission.message === '' || (shouldSendEmail && submission.email === '')}
          onClick={closeReview}
        >
          Cloturer l'analyse : {valueMapping[submission.result]}
        </Button>
      </div>
    </Container>
  );
};

const defQuestionStyle = makeStyles((theme) => ({
  textElement: {
    '& textarea:disabled': {
      '&::-webkit-input-placeholder': {
        color: 'transparent',
        opacity: 0,
      },
      '&:-moz-placeholder': {
        color: 'transparent',
        opacity: 0,
      },
      '&::-moz-placeholder': {
        color: 'transparent',
        opacity: 0,
      },
      '&-ms-input-placeholder': {
        color: 'transparent',
        opacity: 0,
      },
    },
  },
  hidden: {
    display: 'none',
  },
}));

const SimpleQuestion = (props) => {
  const classes = useStyles();

  const simpleQuestionStyle = defQuestionStyle();
  const parent = useRef();

  return (
    <Container
      ref={parent}
      component="div"
      maxWidth={false}
      className={
        classes.checkContent +
        (props.hidden ? ' ' + simpleQuestionStyle.hidden : '') +
        // (typeof props.reviewState[props.name] !== 'boolean' ? ' notSubmitted' : '') +
        (props.reviewState[props.name] === undefined ? ' notSubmitted' : '') +
        (!props.reviewEditable ? ' notEditable' : '')
      }
    >
      <Box>
        {props.text && (
          <Typography variant="h6" style={{ marginBottom: 10 }}>
            {props.text}
          </Typography>
        )}

        {props.additional ? (
          <div style={{ margin: '10px 0' }}>
            {props.additional.text} {props.additional.value}
          </div>
        ) : (
          ''
        )}

        {props.children}

        <p className={classes.inlineElement} dangerouslySetInnerHTML={{ __html: props.question }}></p>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            disabled={!props.reviewEditable}
            labelId={props.name}
            id={props.name}
            value={props.reviewState[props.name] !== undefined ? props.reviewState[props.name] : ''}
            name={props.name}
            onChange={props.handleChange}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value={true}>Oui</MenuItem>
            <MenuItem value={false}>Non</MenuItem>
            <MenuItem value={'NA'}>Non-Applicable</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box className={classes.commentBox + ' ' + (props.reviewState[props.name] !== undefined ? classes.showCommentBox : '')}>
        {/* (typeof props.reviewState[props.name] === 'boolean' ? classes.showCommentBox : '') */}
        <p> Commentaire</p>
        <TextField
          InputProps={{ className: simpleQuestionStyle.textElement }}
          disabled={!props.reviewEditable}
          name={props.name + '_message'}
          onChange={(e) => props.handleChange(e, parent)}
          value={props.reviewState[props.name + '_message']}
          placeholder="Insérer un commentaire (optionnel)"
          variant="outlined"
          multiline={true}
          rows={4}
          fullWidth={true}
        />
      </Box>
    </Container>
  );
};

// const Adress = (props) => {
//   const classes = useStyles();

//   const streetNumber = `${props.streetBox ? props.streetBox + ' ' : ''}${props.streetNumber}`;
//   const streetName = `${streetNumber ? streetNumber + ', ' : ''}${props.street}`;
//   return (
//     <Container className={classes.adress}>
//       <Typography variant="h6" style={{ marginBottom: 10 }}>
//         {props.title}
//       </Typography>

//       <p>{streetName}</p>
//       <p>
//         {props.postalCode ? props.postalCode + ' ' : ''}
//         {props.locality}
//       </p>
//       <p>{props.country}</p>
//     </Container>
//   );
// };
