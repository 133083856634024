import { ADMIN, HELPDESK } from '../../../../routing/routes';
import { Link } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const ticketStyle = makeStyles((theme) => ({
  linkContainer: {
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    color: '#555',

    border: '1px solid #666',
    borderRadius: 10,
    pointer: 'cursor',
    padding: '10px 10px',

    '&.solved': {
      backgroundColor: '#bbbbbb',
    },

    '&:hover': {
      backgroundColor: '#dddddd',
    },
  },
  unread: {
    width: '10%',
    maxWidth: 50,
    aspectRatio: '1/1',
    alignSelf: 'center',
    display: 'grid',
    placeItems: 'center',
    '& .isUnread': {
      width: '80%',
      maxWidth: 24,
      aspectRatio: 1 / 1,
      borderRadius: '50%',
      backgroundColor: '#f44336',
    },
  },
  messageDate: {
    fontSize: '.9rem',
  },
  ticketDate: {
    width: '20%',
    minWidth: '10ch',
    padding: 5,
    textAlign: 'center',
  },
  messageContainer: {
    flex: 1,
    paddingInline: 10,
  },
  from: {
    '& p': {
      marginTop: 0,
      marginBottom: 5,
    },
  },
  message: {},
}));

export const TicketBox = (props) => {
  const ticketClasses = ticketStyle();
  const { ticket_id, ticket_data } = props;

  if (!ticket_id) return <></>;

  const textLimit = 140;

  const lastMessage = ticket_data.messages[ticket_data.messages.length - 1] || {};
  return (
    <Link to={`${ADMIN + HELPDESK}/${ticket_id}`}>
      <div className={`${ticketClasses.linkContainer} ${ticket_data.status.solved ? 'solved' : ''}`}>
        <div className={ticketClasses.unread}>
          <div className={`${lastMessage.sender === 'client' ? 'isUnread' : ''}`}></div>
        </div>
        <div className={ticketClasses.messageContainer}>
          <div>
            <Typography sx={{ fontWeight: 500 }}>
              {ticket_data.status.firstname} {ticket_data.status.lastname}
            </Typography>
            <Typography sx={{ fontWeight: 500 }}>
              AAP: {ticket_data.status.aapId} / Topic: {ticket_data.status.topic}
            </Typography>
          </div>
          <div className={ticketClasses.from}>{lastMessage.sender === 'client' ? <p className="font-medium blue">Dernier message:</p> : <p>Dernière réponse:</p>}</div>
          <div className={ticketClasses.message}>
            {lastMessage.message.substr(0, textLimit)}
            {lastMessage.message.length > textLimit ? '...' : ''}
          </div>
        </div>
        <div className={ticketClasses.ticketDate}>
          <span className={ticketClasses.messageDate}>[{new Date(lastMessage.time).toLocaleDateString('fr-FR')}]</span>
        </div>
      </div>
    </Link>
  );
};
