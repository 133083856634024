import { colorStyles } from '../utils';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

// import EvalGrid from '../../../assets/files/Grille de lecture des criteres de selection_Be Circular_Version Finale.xlsx';
// import EvalGrid from '../../../assets/files/Bc2022_Grille_de_lecture_des_criteres_de_selection_2eme_tour_V2.xlsx';
// import EvalGrid from '../../../assets/files/Grille_analyse_1ertour_BeCircular_2023.xlsx';
// import EvalGrid from '../../../assets/files/Grille_evaluation_Round2_Be_Circular_2023.xlsx'
// import EvalGridTransition from '../../../assets/files/Grille_evaluation_TRANSITION_2023.docx'
// import EvalGrid from '../../../assets/files/Grille_analyse_1er_tour_BeCircular_2024.xlsx'
// import EvalGridNL from '../../../assets/files/Grille_analyse_1er_tour_BeCircular_2024_nl.xlsx'
import EvalGrid from '../../../assets/files/Grille_evaluation_Round2_Be_Circular_2024.xlsx'
import EvalGridNL from '../../../assets/files/Grille_evaluation_Round2_Be_Circular_2024.xlsx'

export const ReviewerInstructions = () => {
  const colors = colorStyles();

  return (
    <>
      <Typography variant="h5" mb={2}>
        Bienvenue sur votre tableau de bord individuel&nbsp;!
      </Typography>
      <Typography>
        Vous voyez apparaitre les dossiers de candidature à analyser qui vous ont été assignés par votre coordinateur&middot;rice thématique.
      </Typography>
      <br />
      <Typography>
        Sur votre tableau de bord, un projet peut avoir un bord :
      </Typography>
      <ul>
        <li>
          <Typography>
            <Box component="span" sx={{ color: '#ccc' }}>
              Gris
            </Box>{' '}
            : vous n'avez pas encore commencé votre analyse
          </Typography>
        </li>
        <li>
          <Typography>
            <Box component="span" className={colors.blue}>
              Bleu
            </Box>{' '}
            : vous avez commencé votre analyse
          </Typography>
        </li>
        <li>
          <Typography>
            <Box component="span" className={colors.green}>
              Vert
            </Box>{' '}
            : vous avez clôturé votre analyse
          </Typography>
        </li>
      </ul>
      <Typography>
        Pour rappel, seul&middot;e un&middot;e expert&middot;e par administration peut côter un projet. Si vous ne retrouvez pas l’un de vos dossiers, veuillez contacter votre coordinateur&middot;rice thématique :
      </Typography>

      <ul>
        <li>
          <Typography>
            <strong>Alimentation</strong> : <a href="mailto:svokaer@environnement.brussels">Sandrine Vokaer</a>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>3R</strong> : <a href="mailto:ldemarez@environnement.brussels">Lynn Demarez</a>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Nouveaux modèles</strong>: <a href="mailto:agrillot@hub.brussels">Aude Grillot</a>
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>Mobilité circulaire</strong>: <a href="mailto:shollevoet@leefmilieu.brussels">Simon Hollevoet</a>
          </Typography>
        </li>
      </ul>

      <Box sx={{ border: '1px solid black' }} px={1} py={2} mb={2}>

        <Typography mb={2} fontWeight={700} color={'error'} sx={{ textTransform: 'underlined' }}>Deadline pour l’évaluation&nbsp;: 29 août 2024</Typography>

        <Typography>
          Pour anticiper et faciliter le travail de synthèse qui sera effectué par les coordinateur&middot;rice&middot;s, nous vous invitons cependant à clôturer <strong>dès que possible</strong> les dossiers qui ne vous posent pas de questions ou de réflexions particulières. Ceci, bien entendu, dans la mesure de vos possibilités/disponibilités. Un grand merci&nbsp;!
        </Typography>
      </Box>

      <Typography variant="h5" mb={2}>
        Comment évaluer un dossier&nbsp;?
      </Typography>
      <Typography>
        Pour vous guider dans votre analyse et vous aider à donner une cote au projet, <a href={EvalGrid} download="Grille_analyse_1ertour_BeCircular_2023.xlsx"><strong>veuillez utiliser svp la grille d’évaluation sous format Excel</strong></a> (lien de téléchargement ci-
        dessous).

        Cette grille fournit, pour chaque critère, des explications sur les points à analyser, et une échelle de valeurs, permettant de mieux situer la cotation des projets, et ainsi uniformiser autant que possible l’analyse par l’ensemble des experts.
        <br />
        <br />
        Dans le module en ligne, vous pouvez également cliquer sous le titre de chaque critère pour afficher un texte synthétique décrivant chacune des sous-questions de la grille s’y rapportant (attention cependant&nbsp;: <strong>la grille au format Excel est beaucoup plus complète</strong>)
        {/* <br />
        <br />
        La première question, relative à la capacité de portage de l’entreprise candidate, n’est pas constitutive de l’évaluation. Elle n’est donc pas chiffrée et se présente sous la forme d’une appréciation simple : OUI – NON. Cette question permet surtout de formuler des recommandations ou un warning à l’attention des entreprises qui ne semblent pas disposer de ressources suffisantes pour porter le projet. */}
        <br />

        <br />
        L’échelle de valeurs pour la cotation des projets est composée de différents codes couleur : <strong className={colors.green}>Vert</strong> = très bien (16-20/20),{' '}
        <strong className={colors.blue}>Bleu</strong> = bien (11-5/20), <strong className={colors.orange}>Orange</strong> =insuffisant (06-10/20), <strong className={colors.red}>Rouge</strong>= très
        insuffisant (0-05/20).
        <br />
        Pour chaque critère, vous pouvez cliquer sur chacune de ces couleurs et les explications de la grille d’analyse relatives à la couleur/cote apparaitront.
        <br />
        <br />
        Pour chaque critère, après avoir donné votre cote sur /20, nous vous demandons de remplir deux cases qui serviront à alimenter le feedback individuel envoyé aux candidats :
      </Typography>
      <ul>
        <li>
          <Typography>
            Un commentaire d’évaluation{' '}
            <strong>
              <u>obligatoire</u>
            </strong>{' '}
            détaillant votre appréciation de la qualité du dossier et justifiant votre note sur ce critère spécifique sur base des différentes sous-questions de la grille d’analyse. Pour cela, et afin
            d’éviter tout conflit d’intérêt, nous vous demandons de vous appuyer{' '}
            <strong>
              <u>uniquement</u>
            </strong>{' '}
            sur les éléments du formulaire en lien avec la grille d’analyse et votre connaissance de l’écosystème bruxellois. Votre analyse ne doit pas tenir compte de votre connaissance du projet
            /candidat. Nous vous demandons si possible de donner votre justification pour chacune des sous-questions.
          </Typography>
        </li>

        <li>
          <Typography>
            Des pistes de recommandations pour améliorer le projet (
            <u>
              facultatif mais <strong>fortement conseillé</strong>
            </u>
            ).
          </Typography>
        </li>
        <li>
          <Typography>
            <strong>
              <u>Pour les experts de BE uniquement :</u>
            </strong>{' '}
            vous n’êtes pas obligés de coter la viabilité financière mais n’hésitez pas à le faire si vous vous en sentez capable. Il est vivement recommandé de lire la partie financière et le plan financier pour mieux objectiver les autres critères tels que la faisabilité technico-économique (la formation plan financier était destinée à vous aider à mieux comprendre les hypothèses et éléments essentiels du plan financier)
          </Typography>
        </li>
      </ul>

      <Typography variant={"h5"} mb={2}>
        Informations importantes
      </Typography>
      <ul>
        <li>
          <Typography>
            Vos commentaires d'évaluation seront utilisés dans le feedback écrit qui sera envoyé aux candidats. <strong>Soyez donc attentif&middot;ve à la formulation utilisée et exprimez vos observations en termes de points forts/faibles du dossier et de recommandations</strong> utiles au porteur de projet. N'hésitez pas, cependant, à mettre en évidence divers éléments d'analyse&nbsp;: les jurys thématiques opéreront la synthèse de vos commentaires et recommandations et les coordinateurs thématiques se chargeront de les transmettre aux candidats par l'intermédiaire de la plateforme. Dans ce processus d'évaluation en deux tours, vos notes et argumentaires sont donc très importants (nb : les notes ne seront pas communiquées).
          </Typography>
        </li>
        <li>
          <Typography>
            Pour ce qui concerne le niveau d’exigence dans l’évaluation des projets, n’oubliez pas de <strong>tenir compte de la spécificité de la catégorie BeCircular&nbsp;:</strong>
          </Typography>
          <ul>
            <li>
              <Typography>
                <strong>Scale-up (subside jusqu'à 200k)</strong> : il est important d’évaluer la dimension d’envergure régionale. Et les impacts potentiels (environnement, emploi, chaine de valeur, ...) doivent être à la hauteur du subside demandé et bien quantifiés
              </Typography>
            </li>
            <li>
              <Typography>
                <strong>Diversification (subside jusqu’à 80K)</strong> : il est important que le projet reflète l’émergence d’une nouvelle activité en économie circulaire au sein de l’entreprise existante
              </Typography>
            </li>
            {/* <li>
              <Typography>
                <strong>Transition <span className={colors.orange}>[uniquement au 2ème tour]</span> (subside de 50K et un an d’accompagnement)</strong> : les projets de cette catégorie ne sont pas forcément aboutis (ils seront à préciser durant l’année d’accompagnement offerte). Elle concerne des projets de transformation d’une activité existante vers une économie circulaire soit par : la mise en place ambitieuse de bonnes pratiques exemplaires vers la circularité ou la transformation d’une activité vers l’adoption d’un nouveau modèle en économie circulaire. Un questionnaire d’évaluation spécifique a été développé pour ces projets, auquel seuls les reviewers des administrations à qui on a assigné des dossiers transition ont accès (à chaque critère, il suffit de cliquer sur «&nbsp;INFORMATION&nbsp;» pour afficher la question d’évaluation). <strong>Une grille d’évaluation plus exhaustive que ces informations a également été créée et vous permet d’avoir davantage de détails pour orienter votre évaluation (lien de téléchargement plus bas)</strong>
              </Typography>
            </li> */}
            {/* <li>
              <Typography>
                <strong>Catégorie Transition (attention candidatures uniquement soumises et analysées àpd 2ème round le 24 juin)</strong> : cette catégorie alloue un soutien financier de max 50.000 €
                et un accompagnement d’une durée d’an à des projets de transformation d’une activité existante vers une économie circulaire soit par :
              </Typography>
              <ul>
                <li>
                  <Typography>
                    la mise en place ambitieuse de bonnes pratiques exemplaires vers la circularité – exemple : une entreprise qui change de fournisseurs pour s’approvisionner majoritairement en
                    matières et/ou produits durables ;
                  </Typography>
                </li>
                <li>
                  <Typography>
                    la transformation d’une activité vers l’adoption d’un nouveau modèle en économie circulaire – exemple : une entreprise qui transforme son modèle économique de sorte qu’elle loue en
                    service ce qu’elle commercialisait en produit auparavant.
                    <br />
                    Il n’est <u>pas demandé aux projets « transition » de fournir un plan financier</u> comme pour les autres catégories.
                  </Typography>
                </li>
              </ul>
            </li> */}
          </ul>
        </li>
      </ul>

      <Typography variant={"h5"} mb={2}>
        Contact
      </Typography>

      <Typography>
        Si vous avez des questions concernant les instructions ou l’utilisation du module d’évaluation, vous pouvez contacter <a href="mailto:pvandenabeele@environnement.brussels">Patrick van den abeele</a> ou votre coordinateur&middot;rice thématique.
        <br />
        En cas de problème technique avec la plateforme veuillez contacter <a href="mailto:abrowet@gmail.com">Arnaud Browet</a>.
        <br />
        <br />
        {/* <a href={EvalGrid} download="Bc2022_Grille_de_lecture_des_criteres_de_selection_2eme tour.xlsx"> */}
        <a href={EvalGrid} download="Grille_analyse_1er_tour_BeCircular_2024.xlsx">
          <Button variant="outlined" color="primary">
            Télécharger la grille d'évaluation
          </Button>
        </a>
        {/* <br />
        <br />
        <a href={EvalGridNL} download="Grille_analyse_1er_tour_BeCircular_2024_nl.xlsx">
          <Button variant="outlined" color="primary">
            Télécharger la grille d'évaluation - Version NL
          </Button>
        </a> */}
      </Typography>
    </>
  );
};
