import { useContext, useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

/* CONTEXT */
import { FirebaseContext } from '../../firebase';

import logo from '../../assets/images/logo_icon.png';

/* ROUTES */
import * as routes from '../../routing/routes';

/* LAYOUT */
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';

/* STYLES */
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  logo: {
    margin: theme.spacing(1),
    width: '50%',
    maxWidth: 150,
    height: 'intrinsic',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: '#F44436',
    border: '1px solid ' + theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
    padding: '0 15px',
    borderRadius: 10,
  },
}));

export const Login = () => {
  const firebaseState = useContext(FirebaseContext);
  // const history = useHistory();
  const navigate = useNavigate();

  const [inputEmail, setInputEmail] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [formError, setFormError] = useState(null);
  const [formDisabled, setFormDisabled] = useState(false);

  const classes = useStyles();

  /* Redirect user after signin effect */
  useEffect(() => {
    const { user } = firebaseState;
    const { admin, reviewer, master, themeAdmin, helpdesk } = firebaseState.claims;

    if (user) {
      if (admin || reviewer || master || themeAdmin || helpdesk) navigate(routes.ADMIN, { replace: true });
      else {
        firebaseState.app.doSignOut().then(() => {
          window.location.href = routes.userApp;
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseState.user]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormError(null);
    setFormDisabled(true);

    firebaseState.app.doSignInWithEmailAndPassword(inputEmail, inputPassword, rememberMe).then(
      (success) => {
        setFormDisabled(false);
      },
      (error) => {
        let errorMessage;
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage = 'Adresse email non valide';
            break;
          case 'auth/user-disabled':
            errorMessage = 'Votre compte est désactivé';
            break;
          case 'auth/wrong-password':
          case 'auth/user-not-found':
            errorMessage = 'Adresse email/Mot de passe incorrect';
            break;
          default:
            errorMessage = 'Une erreur est survenue, veuillez réessayer';
        }

        setFormError(errorMessage);
        setFormDisabled(false);
      }
    );
  };

  const handleChange = ({ target }) => {
    const { name, value } = target;
    switch (name) {
      case 'email':
        setInputEmail(value);
        break;
      case 'password':
        setInputPassword(value);
        break;
      case 'remember':
        setRememberMe(target.checked);
        break;
      default:
        return;
    }
  };
  const isValid = inputEmail && inputPassword ? true : false;

  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Grid container direction="row" justifyContent="center">
          <img className={classes.logo} src={logo} alt="logo" />
        </Grid>
        <Typography component="h1" variant="h5">
          Administration
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <fieldset disabled={formDisabled} style={{ padding: 0, margin: 0, border: 'none' }}>
            <TextField
              value={inputEmail}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Adresse email"
              name="email"
              autoComplete="email"
              autoFocus={false}
            />
            <TextField
              value={inputPassword}
              onChange={handleChange}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel control={<Checkbox name="remember" onChange={handleChange} checked={rememberMe} color="primary" />} label="Se souvenir de moi" />
            {formError && (
              <Box className={classes.errorMessage}>
                <p>
                  <ErrorIcon style={{ verticalAlign: 'middle', marginRight: 15 }} /> {formError}
                </p>
              </Box>
            )}
            <Button disabled={!isValid || formDisabled} type="submit" fullWidth variant="contained" color="secondary" className={classes.submit}>
              {formDisabled ? <CircularProgress thickness={3} size={24} /> : 'Connexion'}
            </Button>
          </fieldset>
        </form>
      </div>
    </Container>
  );
};
