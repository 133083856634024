import { useState, useEffect, useContext, useMemo, Fragment } from 'react';
import { FirebaseContext, FirebaseDataContext, ReviewersListContext } from '../../../../firebase';

import { LoadingGrid, ErrorGrid, colorStyles } from '../../utils';

import { downloadProjectsAttribution, get_rank, sortByEmail } from '../../master/manageThemes/exportExcel';

/* Layout */
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LoopIcon from '@mui/icons-material/Loop';
import CircularProgress from '@mui/material/CircularProgress';
import FaceIcon from '@mui/icons-material/Face';
// import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const themeListStyles = makeStyles((theme) => ({
  paper: {
    padding: 10,
    marginBottom: 30,
  },
  pageTitle: {
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: 30,
  },
  sectionTitle: {
    marginBottom: 15,
  },
  formControl: {
    width: '100%',
    maxWidth: 'min(500px, calc(100% - 48px))',
  },
  selectThemeContainer: {
    marginBottom: 30,
  },
  donwloadProjectAttributionContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

export const ThemeList = (props) => {
  // const mounted = useRef(true);
  const classes = themeListStyles();

  const firebaseContext = useContext(FirebaseContext);
  const firebaseDataContext = useContext(FirebaseDataContext);
  const reviewersListContext = useContext(ReviewersListContext);

  const [selectedTheme, setSelectedTheme] = useState('');

  // const [readingThemes, setReadingThemes] = useState(false);
  const [themes, setThemes] = useState([]);
  // const [errorReadingThemes, setErrorReadingThemes] = useState();
  const readingThemes = !(firebaseDataContext.themesRead || false);

  const [exportingFile, setExportingFile] = useState(false);

  // const [readingEvaluations, setReadingEvaluations] = useState(true);
  // const [allEvaluations, setAllEvaluations] = useState({});

  const myUid = firebaseContext.user.uid;

  const myThemes = useMemo(() => {
    return themes.filter((theme) => theme.themeAdmin.indexOf(myUid) >= 0);
  }, [themes, myUid]);

  // filter only accepted projects
  /*
  myThemes.forEach((theme) => {
    theme.projects = theme.projects.filter((proj) => {
      const [projAapId, projId] = proj.split('_');

      if (
        firebaseDataContext[`review_${projAapId}Read`] &&
        firebaseDataContext[`review_${projAapId}`][projId] &&
        firebaseDataContext[`review_${projAapId}`][projId].status === 'reviewed' &&
        firebaseDataContext[`review_${projAapId}`][projId].result === 'accepted'
      )
        return true;
      return false;
    });
  });
  */

  const currentTheme = useMemo(() => {
    return myThemes.filter((theme) => theme.name === selectedTheme)[0];
  }, [myThemes, selectedTheme]);

  // const readThemes = useCallback(() => {
  //   setErrorReadingThemes(null);
  //   setReadingThemes(true);

  //   firebaseContext.app.listTheme().then(
  //     (themeCollection) => {
  //       const fetchedThemes = [];
  //       themeCollection.forEach((theme) => {
  //         fetchedThemes.push({ name: theme.id, ...theme.data() });
  //       });

  //       if (!mounted.current) return;
  //       setReadingThemes(false);
  //       setThemes(fetchedThemes);

  //       // update selected theme after refresh
  //       const myFetchedThemes = fetchedThemes.filter((theme) => theme.themeAdmin.indexOf(myUid) >= 0);
  //       if (!mounted.current) return;
  //       if (selectedTheme === '' && myFetchedThemes.length > 0) {
  //         setSelectedTheme(myFetchedThemes[0].name);
  //       }
  //       if (selectedTheme !== '') {
  //         const goodThemes = myFetchedThemes.filter((theme) => theme.name === selectedTheme);
  //         if (!mounted.current) return;
  //         if (goodThemes.length === 0) {
  //           setSelectedTheme(myFetchedThemes.length ? myFetchedThemes[0].name : '');
  //         }
  //       }
  //     },
  //     (themeError) => {
  //       if (!mounted.current) return;
  //       console.error(themeError);
  //       setReadingThemes(false);
  //       setThemes([]);
  //       setErrorReadingThemes({ error: 'Une erreur est survenue, veuilez réessayer.' });
  //     }
  //   );
  // }, [firebaseContext.app, myUid, selectedTheme]);


  useEffect(() => {
    const receivedThemes = firebaseDataContext.themes || [];
    setThemes(receivedThemes);

    // update selected theme after refresh
    const myFetchedThemes = receivedThemes.filter((theme) => theme.themeAdmin.indexOf(myUid) >= 0);

    setSelectedTheme(currentSelectedTheme => {
      if (currentSelectedTheme === '' && myFetchedThemes.length > 0) {
        return myFetchedThemes[0].name;
      }
      else if (currentSelectedTheme !== '') {
        const goodThemes = myFetchedThemes.filter((theme) => theme.name === currentSelectedTheme);
        if (goodThemes.length === 0) {
          return myFetchedThemes.length ? myFetchedThemes[0].name : '';
        }
      }

      return currentSelectedTheme
    })


    // if (selectedTheme === '' && myFetchedThemes.length > 0) {
    //   setSelectedTheme(myFetchedThemes[0].name);
    // }
    // if (selectedTheme !== '') {
    //   const goodThemes = myFetchedThemes.filter((theme) => theme.name === selectedTheme);
    //   if (goodThemes.length === 0) {
    //     setSelectedTheme(myFetchedThemes.length ? myFetchedThemes[0].name : '');
    //   }
    // }
  }, [firebaseDataContext.themes, myUid]);

  const handleSelectedThemeChange = ({ target }) => {
    setSelectedTheme(target.value);
  };

  // useEffect(() => {
  //   readThemes();
  //   return () => {
  //     mounted.current = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // // Read evaluations of all reviewers
  // useEffect(() => {
  //   if (reviewersListContext.readingReviewers) return;

  //   firebaseDataContext.getEvaluationOfReviewers(reviewersListContext.reviewersList).then((gotEvals) => {
  //     setAllEvaluations(gotEvals);
  //     setReadingEvaluations(false);
  //   });

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [reviewersListContext.readingReviewers]);

  const [editProjectReviewersOpen, setEditProjectReviewersOpen] = useState(false);
  const [editReviewersProjectInfo, setEditReviewersProjectInfo] = useState({});

  const handleOpenEditProjectReviewers = (projectInfo) => {
    // setSelectedValue(themeName);
    // console.log(projectInfo)
    setEditReviewersProjectInfo({ ...projectInfo, selectedTheme });
    setEditProjectReviewersOpen(true);
  };

  const handleCloseEditProjectReviewers = (value) => {
    setEditProjectReviewersOpen(false);
  };

  const defineNewProjectReviewers = (curTheme, projectId, newReviewersUid) => {
    return new Promise((resolve, reject) => {
      firebaseContext.app.editReviewersForProject(curTheme.name, projectId, newReviewersUid).then(
        () => {
          setThemes((prevThemes) => {
            const newThemes = prevThemes.map((theme) => {
              if (theme.name !== curTheme.name) return theme;

              theme.reviewers[`${projectId}`] = newReviewersUid;
              return theme;
            });

            return newThemes;
          });
          resolve(0);
        },
        (error) => {
          console.error(error);
          reject(-1);
        }
      );
    });
  };

  const refreshThemesAndUsers = () => {
    // readThemes();
    reviewersListContext.readReviewersList();
    // setReadingEvaluations(true);
  };

  if (readingThemes || reviewersListContext.readingReviewers || !firebaseDataContext.projectsRead || !firebaseDataContext.evaluation_flagRead) {
    //|| readingEvaluations
    return <LoadingGrid />;
  }
  // if (errorReadingThemes) {
  //   return <ErrorGrid error={errorReadingThemes.error} refresh={readThemes} />;
  // }
  if (reviewersListContext.errorReadingReviewers) {
    return <ErrorGrid error={reviewersListContext.errorReadingReviewers.error} refresh={reviewersListContext.readReviewersList} />;
  }


  const downloadThemeAttribution = () => {
    setExportingFile(true);

    const evaluationFlag = firebaseDataContext.evaluation_flag;
    const evaluationQuestions = (firebaseDataContext.evaluationDefinitions[evaluationFlag] || {}).evaluationQuestions || [];

    const administrativeReviews = firebaseDataContext.administrativeReviews || {};
    const evaluationsGlobales = firebaseDataContext.evaluationsGlobales || {};

    const currentProjects = {};

    for (const k of Object.keys(firebaseDataContext.userProjects || {})) {
      if (currentTheme.projects.includes(k)) {
        currentProjects[k] = firebaseDataContext.userProjects[k];
      }
    }

    const appendReviewersList = reviewersListContext.reviewersList.map((r) => ({ uid: r.uid, email: r.email }));

    if (appendReviewersList.filter((r) => r.uid === firebaseContext.user.uid).length === 0) {
      appendReviewersList.push({
        uid: firebaseContext.user.uid,
        email: firebaseContext.user.email,
      });
    }

    downloadProjectsAttribution(
      'Attribution des évaluateurs',
      `suivi_${selectedTheme.replace(' ', '_')}.xlsx`,
      appendReviewersList,
      reviewersListContext.reviewersEvaluations,
      [currentTheme],
      currentProjects,
      firebaseDataContext.definitions,
      evaluationQuestions,
      administrativeReviews,
      evaluationsGlobales
    ).then(() => {
      setExportingFile(false);
    });
  };

  const reviewersLimit = parseInt((firebaseDataContext.evaluationDefinitions[firebaseDataContext.evaluation_flag] || {}).reviewersLimit) || 6;

  const evaluationFlag = firebaseDataContext.evaluation_flag;

  const evaluationDefinitions = (firebaseDataContext.evaluationDefinitions[evaluationFlag] || {});
  const evaluationParameters = evaluationDefinitions.evaluationParameters || {};

  const administrativeReviews = firebaseDataContext.administrativeReviews || {};

  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography className={classes.pageTitle} variant="h1" component="div">
            Gestion des Reviewers
          </Typography>
        </Box>

        <Box>
          <Button variant="contained" color="primary" startIcon={<LoopIcon />} onClick={refreshThemesAndUsers}>
            Rafraichir
          </Button>
        </Box>
      </Box>
      {myThemes.length <= 0 && (
        <Paper className={classes.paper} elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>Vous n'avez aucune thématique à administrer pour le moment</Box>
            </Grid>
          </Grid>
        </Paper>
      )}
      {myThemes.length > 0 && (
        <Paper className={classes.paper} elevation={2}>
          <Grid container spacing={2} className={classes.selectThemeContainer}>
            <Grid item xs={12}>
              <p>Thématique séléctionnée</p>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="select-outlined-label">Sélectionnez une thématique</InputLabel>
                <Select labelId="select-outlined-label" id="select-outlined" value={selectedTheme} onChange={handleSelectedThemeChange} label="Sélectionnez une thématique">
                  {myThemes.map((theme) => (
                    <MenuItem key={theme.name} value={theme.name}>
                      {theme.name}
                    </MenuItem>
                  ))}
                  {/*  */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {selectedTheme !== '' && (
            <>
              <Box className={classes.donwloadProjectAttributionContainer}>
                <Button disabled={exportingFile} variant="outlined" color="primary" onClick={downloadThemeAttribution}>
                  Télécharger le fichier de suivi de la thématique
                </Button>
              </Box>
              <Grid container spacing={2}>
                {currentTheme.projects.length === 0 && (
                  <Grid item xs={12}>
                    <Box>Cette thématique ne contient aucun projet pour le moment.</Box>
                  </Grid>
                )}
                {currentTheme.projects.length > 0 &&
                  currentTheme.projects
                    .sort((p1, p2) => {
                      const pn1 = ((firebaseDataContext.userProjects[p1] || {}).status || {}).project_number || 0;
                      const pn2 = ((firebaseDataContext.userProjects[p2] || {}).status || {}).project_number || 0;

                      if (pn1 < pn2) return -1;
                      if (pn1 > pn2) return 1;
                      return 0;
                    })
                    .map((project) => {
                      // remove non read projects (filtered)
                      if (
                        !(project in firebaseDataContext.userProjects) ||
                        !firebaseDataContext.userProjects[project].status.submitted
                      ) {
                        return <Fragment key={project}></Fragment>;
                      }

                      // filter for the evaluated aapId's
                      if (evaluationDefinitions && evaluationDefinitions.evaluatedAAPID) {
                        if (!evaluationDefinitions.evaluatedAAPID.includes(firebaseDataContext.userProjects[project].status.aap_id)) {
                          return <Fragment key={project}></Fragment>;
                        }
                      }
                      // filter project for admnistrative review if required
                      if (evaluationParameters.validAdministrativeResult) {
                        if (
                          !(project in administrativeReviews) ||
                          (administrativeReviews[project].flag !== evaluationFlag) ||
                          (administrativeReviews[project].status !== 'reviewed') ||
                          !evaluationParameters.validAdministrativeResult.includes(administrativeReviews[project].result)) {
                          return <Fragment key={project}></Fragment>;
                        }
                      }

                      return (
                        <Grid item key={project} xs={12} sm={6} md={6} lg={4}>
                          <ManageProjectReviewers
                            allEvaluations={reviewersListContext.reviewersEvaluations}
                            project={project}
                            // currentTheme={currentTheme}
                            currentReviewers={currentTheme.reviewers[project] || []}
                            reviewersList={reviewersListContext.reviewersList}
                            openEditProjectReviewers={handleOpenEditProjectReviewers}
                          />
                        </Grid>
                      );
                    })}
              </Grid>
            </>
          )}
          <EditProjectReviewers
            defineNewProjectReviewers={defineNewProjectReviewers}
            reviewersList={reviewersListContext.reviewersList}
            projectInfo={editReviewersProjectInfo}
            open={editProjectReviewersOpen}
            onClose={handleCloseEditProjectReviewers}
            reviewersLimit={reviewersLimit}
          />
        </Paper>
      )}
    </Container>
  );
};

const backdropStyles = makeStyles((theme) => ({
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: '#ccc',
    backgroundColor: '#3a3838a1',
    display: 'none',
  },
  showBackdrop: {
    display: 'block',
  },
}));

const manageProjectReviewersStyles = makeStyles((theme) => ({
  manageProjectReviewersPaperContainer: {
    position: 'relative',
    paddingTop: 15,
    paddingBottom: 15,
    marginBottom: 10,
    cursor: 'pointer',
  },
  projectTitle: {
    marginBottom: 5,
  },
  formControl: {
    width: '100%',
    maxWidth: 'min(500px, calc(100% - 48px))',
  },
  deleteReviewersButton: {
    marginRight: 10,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: 'white',
    },
  },
  deleteReviewersButtonIcon: {
    width: 20,
    height: 20,
  },
  noProjectReviewersContainer: {
    marginTop: 10,
    fontSize: '.8rem',
    color: theme.palette.error.main,
  },
  projectIdentity: {
    fontSize: '.9rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
    marginBottom: 20,
  },
  projectCategory: {
    fontSize: '.9rem',
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
  },
  reviewerListTitle: {
    marginTop: 20,
    fontWeight: 700,
    color: '#666',
  },
  reviewerItem: {
    fontStyle: 'italic',
    '&:before': {
      content: '""',
      display: 'inline-block',
      verticalAlign: 'middle',
      width: 8,
      height: 8,
      marginRight: 10,
      // backgroundColor: '#666',
      borderRadius: '50%',
    },
  },
}));

const ManageProjectReviewers = (props) => {
  const colors = colorStyles();
  const classes = manageProjectReviewersStyles();

  const firebaseDataContext = useContext(FirebaseDataContext);

  const projectId = props.project;

  const allEvaluations = props.allEvaluations;

  const projectData = firebaseDataContext.userProjects[projectId] || {};

  const currentReviewersEmail = props.reviewersList.filter((rev) => props.currentReviewers.indexOf(rev.uid) >= 0).sort(sortByEmail);

  const [paperElevation, setPaperElevation] = useState(2);

  const handleMouseOver = () => {
    setPaperElevation(5);
  };
  const handleMouseLeave = () => {
    setPaperElevation(2);
  };
  const openReviewerList = () => {
    props.openEditProjectReviewers({ projectId });
  };

  const evaluation_flag = firebaseDataContext.evaluation_flag ?? "round1";

  return (
    <Paper elevation={paperElevation} className={classes.manageProjectReviewersPaperContainer} onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave} onClick={openReviewerList}>
      <Container component="div" maxWidth={false} className={classes.manageProjectReviewersContainer}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography className={classes.projectTitle} variant="h6" component="div">
              {(projectData.status || {}).project_number ? `#${projectData.status.project_number} - ` : ''}
              {(projectData.data && projectData.data.acronyme) || '???'}
            </Typography>
          </Box>
          <EditIcon style={{ color: '#9b9b9b' }}></EditIcon>
        </Box>

        {projectData.data && (
          <div>
            <div className={classes.projectIdentity}>{projectData.data.identity}</div>
            <div className={classes.projectCategory}>{firebaseDataContext.definitionsRead && firebaseDataContext.definitions[projectData.status.aap_id].name_fr}</div>
          </div>
        )}

        {currentReviewersEmail.length > 0 && (
          <>
            <div className={classes.reviewerListTitle}>Liste des reviewers:</div>
            <List>
              {currentReviewersEmail.map((rev) => {
                const has_started = allEvaluations[rev.uid] && allEvaluations[rev.uid][projectId];
                const has_finished = has_started && allEvaluations[rev.uid][projectId][evaluation_flag];
                return (
                  <ListItem
                    key={`${props.project}_${rev.uid}`}
                    className={clsx(classes.reviewerItem, {
                      [colors.gray]: !has_started && !has_finished,
                      [colors.blue]: has_started && !has_finished,
                      [colors.green]: has_started && has_finished,
                    })}
                  >
                    <ListItemText primaryTypographyProps={{ style: { fontSize: '0.9rem', fontWeight: 500 } }} primary={rev.email} />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}

        {currentReviewersEmail.length === 0 && (
          <Container component="div" maxWidth={false}>
            <Box display="flex" alignItems="center" className={classes.noProjectReviewersContainer}>
              <Box style={{ marginRight: 5 }}>
                <FaceIcon />
              </Box>
              <Box>Il n'y a aucun reviewer attribué...</Box>
            </Box>
          </Container>
        )}
      </Container>
      {/* <Container component="div" maxWidth={false} className={clsx(backdropClasses.backdrop, { [backdropClasses.showBackdrop]: editingProjectReviewers })}>
        <Grid style={{ height: '100%' }} container direction="column" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" />
        </Grid>
      </Container> */}
    </Paper>
  );
};

const editProjectReviewersStyles = makeStyles((theme) => ({
  projectName: {
    fontWeight: 500,
    fontSize: '1rem',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '5px 0',
  },
  projectBy: {
    fontSize: '.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '5px 0',
    marginBottom: 10,
  },
  headInfo: {
    fontSize: '.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '5px 0',
  },

  projectObjectif: {
    marginTop: 10,
    fontSize: '.8rem',
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid',
    borderRadius: 10,
    padding: 8,
    maxHeight: 'min(30vh, 300px)',
    overflowY: 'auto',
  },
  formContainer: {
    marginTop: 30,
    marginBottom: 10,
  },
  formControl: {
    width: '100%',
    maxWidth: 'min(500px, calc(100% - 48px))',
  },
  settingThemeError: {
    fontStyle: 'italic',
    fontWeight: 500,
    fontSize: '.8rem',
    color: theme.palette.error.main,
  },
  deleteReviewerButton: {
    padding: 3,
    minWidth: 0,
    marginRight: 5,
    backgroundColor: '#bdbdbd',
    borderRadius: '50%',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  deleteReviewerIcon: {
    width: 20,
    height: 20,
  },
  reviewerListTitle: {
    marginTop: 20,
    fontWeight: 700,
    color: '#666',
  },
  reviewerItem: {
    fontStyle: 'italic',
    color: '#333',
  },
  noProjectReviewersContainer: {
    marginTop: 10,
    fontSize: '.8rem',
    color: theme.palette.error.main,
  },
  addReviewerBox: {
    marginTop: 25,
  },
  addThemeAdminButton: {
    marginTop: 5,
  },
}));

const EditProjectReviewers = (props) => {
  const backdropClasses = backdropStyles();
  const classes = editProjectReviewersStyles();

  const firebaseDataContext = useContext(FirebaseDataContext);

  const { onClose, open, projectInfo } = props;

  const currentTheme = firebaseDataContext.themes.filter((theme) => theme.name === projectInfo.selectedTheme)[0];

  // const [selectedNewReviewer, setSelectedNewReviewer] = useState('');
  const [selectedAutocompleteNewReviewer, setSelectedAutocompleteNewReviewer] = useState(null);

  // const handleChangeSelectedReviewer = ({ target }) => {
  //   setSelectedNewReviewer(target.value);
  // };

  const handleChangeAutocompleteReviewer = (event, newValue) => {
    // setSelectedNewReviewer(newValue ? newValue.uid : '');
    setSelectedAutocompleteNewReviewer(newValue);
  };

  const handleClose = () => {
    // setSelectedNewReviewer('');
    setSelectedAutocompleteNewReviewer(null);
    onClose(false);
  };

  const [editingProjectReviewers, setEditingProjectReviewers] = useState(false);

  // const handleReviewerChange = (revUid, remove) => {
  const handleReviewerChange = (rev, remove) => {
    setEditingProjectReviewers(true);
    // setSelectedNewReviewer('');
    setSelectedAutocompleteNewReviewer(null);
    const revUid = rev.uid;

    const currentReviewersUid = currentReviewers.map((rev) => rev.uid);

    let newReviewersUid;
    if (remove) {
      newReviewersUid = currentReviewersUid.filter((curRevUid) => curRevUid !== revUid);
    } else {
      newReviewersUid = [...currentReviewersUid, revUid];
    }

    props.defineNewProjectReviewers(currentTheme, projectInfo.projectId, newReviewersUid).then(
      (success) => {
        setEditingProjectReviewers(false);
      },
      (error) => {
        // if (!remove && revUid) setSelectedNewReviewer(revUid);
        if (!remove && revUid) setSelectedAutocompleteNewReviewer(rev);
        setEditingProjectReviewers(false);
      }
    );
  };

  const addSelectedReviewer = () => {
    // handleReviewerChange(selectedNewReviewer);
    handleReviewerChange(selectedAutocompleteNewReviewer);
  };

  const removeReviewer = (revUid) => {
    handleReviewerChange(revUid, true);
  };

  if (!open) return <></>;

  const projectData = firebaseDataContext.userProjects[`${projectInfo.projectId}`] || {};

  const aapName = projectData.status ? firebaseDataContext.definitions[`${projectData.status.aap_id}`].name_fr : 'Unknown AAP';

  // const projectDescId = `${projectInfo.aapId}_${projectInfo.projectId}`;

  const currentReviewers = props.reviewersList.filter((rev) => (currentTheme.reviewers[projectInfo.projectId] || []).indexOf(rev.uid) >= 0).sort(sortByEmail);
  const currentReviewersRank = currentReviewers
    .map((r) => get_rank(r.email))
    .reduce((pre, cur) => {
      pre[cur] = (pre[cur] ?? 0) + 1;
      return pre;
    }, {});

  const availableRanks = [];
  [1, 2, 3, 4, 100].forEach((r) => {
    if (r === 4) {
      if (!(r in currentReviewersRank) || currentReviewersRank[r] < props.reviewersLimit) {
        availableRanks.push(r);
      }
      return;
    }
    if (!(r in currentReviewersRank)) {
      availableRanks.push(r);
    }
  });

  const availableReviewers = props.reviewersList.filter((rev) => (currentTheme.reviewers[projectInfo.projectId] || []).indexOf(rev.uid) < 0 && availableRanks.includes(get_rank(rev.email)));

  // sort available reviewers by email
  availableReviewers.sort((r1, r2) => {
    const e_1 = r1.email;
    const e_2 = r2.email;

    if (e_1 === e_2) return 0;
    return e_1 < e_2 ? -1 : 1;
  });

  return (
    <Dialog fullWidth={true} maxWidth={'md'} className={classes.dialogContainer} onClose={handleClose} aria-labelledby="reviewers-dialog-title" open={open}>
      <DialogTitle id="reviewers-dialog-title">
        <Box display="flex" alignItems="center">
          <Box flex={1}>Reviewers du projet</Box>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {projectData.data && (
          <>
            <div className={classes.projectName}>{projectData.data.acronyme || '???'}</div>
            <div className={classes.projectBy}>
              par <strong>{projectData.data.identity || '???'}</strong>
            </div>

            <div className={classes.headInfo}>
              Catégorie: <strong>{aapName}</strong>
            </div>
            <div className={classes.headInfo}>
              ID Project: <strong>{projectInfo.projectId}</strong>
            </div>

            <div className={classes.projectObjectif}>{projectData.data.description || '???'}</div>
          </>
        )}

        {availableReviewers.length > 0 && currentReviewers.length < props.reviewersLimit && (
          <Box display="flex" alignItems="center" flexDirection="column" className={classes.addReviewerBox}>
            <Box flexGrow={1} display="flex" justifyContent="center" style={{ width: '100%', maxWidth: 500 }}>
              <Autocomplete
                id="country-select-demo"
                // sx={{ width: 300 }}
                fullWidth
                options={availableReviewers}
                autoHighlight
                clearOnBlur
                getOptionLabel={(option) => option.email || ''}
                value={selectedAutocompleteNewReviewer}
                onChange={handleChangeAutocompleteReviewer}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.email}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Ajouter un reviewer"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />

              {/* <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="select-outlined-label">Ajouter un reviewer</InputLabel>
                <Select labelId="select-outlined-label" id="select-outlined" value={selectedNewReviewer} onChange={handleChangeSelectedReviewer} label="Ajouter un reviewer">
                  <MenuItem value="">&nbsp;</MenuItem> 
                  {availableReviewers.map((rev) => (
                    <MenuItem key={rev.uid} value={rev.uid}>
                      {rev.email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </Box>
            <Box>
              {selectedAutocompleteNewReviewer && (
                <Button variant="contained" color="secondary" className={classes.addThemeAdminButton} onClick={addSelectedReviewer}>
                  Ajouter
                </Button>
              )}
            </Box>
          </Box>
        )}

        {currentReviewers.length > 0 && (
          <>
            <div className={classes.reviewerListTitle}>Liste des reviewers:</div>
            <List>
              {currentReviewers.map((rev) => {
                return (
                  <ListItem key={`${props.project}_${rev.uid}`} className={classes.reviewerItem}>
                    {/* <ListItemAvatar>
                      <IconButton className={classes.deleteThemeButton} onClick={() => console.log('clicked')}>
                        <Avatar className={classes.deleteThemeButtonIcon}>
                          <DeleteForeverIcon />
                        </Avatar>
                      </IconButton>
                    </ListItemAvatar> */}

                    <ListItemIcon className={classes.deleteReviewerButton} onClick={() => removeReviewer(rev)}>
                      <DeleteForeverIcon className={classes.deleteReviewerIcon} />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ style: { fontSize: '0.9rem' } }} primary={rev.email} />
                  </ListItem>
                );
              })}
            </List>
          </>
        )}

        {currentReviewers.length === 0 && (
          <Container component="div" maxWidth={false}>
            <Box display="flex" alignItems="center" className={classes.noProjectReviewersContainer}>
              <Box style={{ marginRight: 5 }}>
                <FaceIcon />
              </Box>
              <Box>Il n'y a aucun reviewer attribué...</Box>
            </Box>
          </Container>
        )}

        <Container component="div" maxWidth={false} className={clsx(backdropClasses.backdrop, { [backdropClasses.showBackdrop]: editingProjectReviewers })}>
          <Grid style={{ height: '100%' }} container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress color="inherit" />
          </Grid>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
