/* LAYOUT */
import { CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CachedIcon from '@mui/icons-material/Cached';

export const colorStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.secondary.main,
    '&:before': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  blue: {
    color: theme.palette.primary.main,
    '&:before': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  orange: {
    color: theme.palette.warning.main,
  },
  red: {
    color: theme.palette.error.main,
  },
  borderBlue: {
    borderColor: theme.palette.primary.main,
  },
  borderOrange: {
    borderColor: theme.palette.warning.main,
  },
  borderRed: {
    borderColor: theme.palette.error.main,
  },
  white: {
    color: 'white',
  },
  gray: {
    color: '#666',
    '&:before': {
      backgroundColor: '#666',
    },
  },
  bckgGreen: {
    backgroundColor: theme.palette.secondary.main,
  },
  bckgBlue: {
    backgroundColor: theme.palette.primary.main,
  },
  bckgOrange: {
    backgroundColor: theme.palette.warning.main,
  },
  bckgRed: {
    backgroundColor: theme.palette.error.main,
  },
}));

export const LoadingGrid = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <CircularProgress thickness={6} size={50} />
    </Grid>
  );
};

const errorStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
}));
export const ErrorGrid = (props) => {
  const classes = errorStyles();
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <p className={classes.errorMessage}>{props.error} </p>
      <Box>
        <IconButton aria-label="refresh" color="primary" onClick={props.refresh}>
          <CachedIcon fontSize="large" />
        </IconButton>
      </Box>
    </Grid>
  );
};
