import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const s2ab = (s) => {
  var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  var view = new Uint8Array(buf); //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};
export const get_rank = (email) => {
  let rank;
  if (email.includes('@leefmilieu.brussels') || email.includes('@environnement.brussels')) {
    rank = 1;
  } else if (email.includes('@hub.brussels')) {
    rank = 2;
  } else if (email.includes('@sprb.brussels') || email.includes('@gob.brussels')) {
    rank = 3;
  } else if (email.includes('@finance.brussels')) {
    rank = 100;
  } else {
    rank = 4;
  }
  return rank;
};

export const sortByEmail = (a, b) => {
  const rank_a = get_rank(a.email);
  const rank_b = get_rank(b.email);

  if (rank_a === rank_b) {
    return a.email < b.email ? -1 : 1;
  }
  return rank_a - rank_b;
};

export const get_rev_short = (i) => {
  let revShort;
  switch (i) {
    case 1:
      revShort = 'BE';
      break;
    case 2:
      revShort = 'HUB';
      break;
    case 3:
      revShort = 'BEE';
      break;
    case 4:
      revShort = 'Expert1';
      break;
    case 5:
      revShort = 'Expert2';
      break;
    case 6:
      revShort = 'Finance';
      break;
    default:
      revShort = `Rev ${i}`;
  }
  return revShort;
};

export const downloadProjectsAttribution = async (
  sheetname,
  outputFilename,
  userList,
  all_evaluations,
  themes,
  userProjects,
  definitions,
  evaluationQuestions,
  administrativeReviews,
  evaluationsGlobales
) => {
  // getEvaluationOfReviewers()
  return new Promise((resolve, reject) => {
    const maxNumberOfReviewers = 6;

    const columns = [
      { title: 'Project #' },
      { title: 'Catégorie' },
      { title: 'Id' },
      { title: 'Thématique' },
      { title: 'Submitted' },
      { title: 'Catégorie Anticipée' },
      { title: 'Acronyme' },
      { title: 'Identité' },
      { title: 'Email' },
      { title: 'Email Contact' },
      { title: 'Statut' },
      { title: 'Description' },
      { title: 'Explication' },
      { title: 'Budget estimé' },
      { title: 'Coopérative ou économie sociale?' },
      { title: 'Gestionnaire de thématique' },
      { title: 'Evaluateurs' },
      //   { title: 'Moyenne Cote - Adequation' },
      //   { title: 'Moyenne Cote - Impact' },
      //   { title: 'Moyenne Cote - Faisabilite' },
      //   { title: 'Moyenne Cote - Viabilite' },
      //   { title: 'Moyenne Cote - Emploi' },
      //   { title: 'Moyenne Cote - Majoration urbaine' },
    ];

    // add columns administrative review
    columns.push({ title: '' });
    columns.push({ title: `Evaluation Administrative - Statut` });
    columns.push({ title: `Evaluation Administrative - Message` });
    columns.push({ title: '' });

    // add columns eval Globale
    evaluationQuestions.forEach((q) => {
      const name = q.name.replace('_', ' ');
      columns.push({ title: `${name} - Evaluation Globale [Cote]` });
      columns.push({ title: `${name} - Evaluation Globale [Justification]` });
      columns.push({ title: `${name} - Evaluation Globale [Recommandation]` });
    });

    // add mean of reviewers Evaluation
    columns.push({ title: '' });
    evaluationQuestions.forEach((q) => {
      const name = q.short_title.fr.replace('_', ' ');
      columns.push({ title: `Moyenne Cote - ${name}` });
    });

    // add columns reviewers Evaluation
    evaluationQuestions.forEach((q) => {
      const name = q.name.replace('_', ' ');
      columns.push({ title: '' });
      for (let i = 1; i <= maxNumberOfReviewers; i++) {
        const revShort = get_rev_short(i);

        columns.push({ title: `${name} - ${revShort} [Cote]` });
        columns.push({ title: `${name} - ${revShort} [Justification]` });
        columns.push({ title: `${name} - ${revShort} [Recommandation]` });
      }
    });

    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: sheetname,
      Subject: 'Theme attribution',
      Author: 'Arnaud Browet',
      CreatedDate: new Date(),
    };

    wb.SheetNames.push(sheetname);

    // set the header
    const ws_data = [columns.map((c) => c.title)];

    const attributionDone = {};

    themes.forEach((theme) => {
      theme.projects.forEach((p) => (attributionDone[p] = theme.name));
    });

    const userEmails = userList.reduce((prev, cur) => {
      return { ...prev, [cur.uid]: cur.email };
    }, {});

    // // iterate over projects
    Object.keys(userProjects).forEach((pId) => {
      const project_data = userProjects[pId];

      const aapId = project_data.status.aap_id;

      const aapName = definitions[aapId]['name_fr'];

      // only iterate on submitted project
      if (project_data.status.project_number !== undefined) {
        const outputRow = [project_data.status.project_number, aapName, pId];

        outputRow.push(attributionDone[pId] || '');

        // const description = project_data.status.aap_id === 'transition' ? project_data.data.transition_nature_pilote || '' : project_data.data.presentation_objectif || '';
        // const explain =
        //   project_data.status.aap_id === 'transition'
        //     ? project_data.data.transition_resultats || ''
        //     : project_data.status.aap_id === 'scaleup'
        //     ? project_data.data.presentation_expansion || ''
        //     : project_data.data.presentation_offre || '';

        let description, explain;
        switch (project_data.status.aap_id) {
          case ('round1'):
            description = project_data.data.description
            explain = project_data.data.explain
            break;
          case ('transition'):
            description = project_data.data.transition_nature_pilote;
            explain = project_data.data.transition_resultats
            break;
          case ('scaleup'):
          case ('diversification'):
          case ('starter'):
            description = project_data.data.description
            explain = project_data.data.storytelling
            break
          default:
            description = ''
            explain = ''
        }

        outputRow.push.apply(outputRow, [
          project_data.status.submitted ?? 'not submitted',
          project_data.data.anticipated_category || '',
          project_data.data.acronyme || '',
          project_data.data.identity || '',
          project_data.data.email || '',
          project_data.data.email_contact || '',
          project_data.data.status || '',
          description,
          explain,
          project_data.data.montant_subvention || 'n/a',
          project_data.data.taux || ""
        ]);

        if (attributionDone[pId]) {
          const projTheme = attributionDone[pId];
          const curTheme = themes.filter((t) => t.name === projTheme)[0];

          if (curTheme) {
            // add theme admin
            outputRow.push(curTheme.themeAdmin.map((adminUid) => userEmails[adminUid] || '[***]').join('\n'));

            // const meanEvalutionScore = [
            //   { name: 'adequation', total: 0, nb: 0, values: [] },
            //   { name: 'impact', total: 0, nb: 0, values: [] },
            //   { name: 'faisabilite', total: 0, nb: 0, values: [] },
            //   { name: 'viabilite', total: 0, nb: 0, values: [] },
            //   { name: 'emploi', total: 0, nb: 0, values: [] },
            //   { name: 'majoration_urbaine', total: 0, nb: 0, values: [] },
            // ];

            const meanEvalutionScore = evaluationQuestions.map((q) => {
              return {
                name: q.name,
                total: 0,
                nb: 0,
                values: [],
              };
            });

            const projReviewersUnsorted = curTheme.reviewers[`${pId}`] || [];
            const projReviewersWithEmail = projReviewersUnsorted.map((pru) => ({ uid: pru, email: userEmails[pru] }));
            projReviewersWithEmail.sort(sortByEmail);

            const correctlyAttributedProjReviewers = Array(maxNumberOfReviewers).fill({ uid: 'fakeUid' });
            let nb_out_rev = 0;
            projReviewersWithEmail.forEach((prwm) => {
              const rank = get_rank(prwm.email);
              if (rank < 4) {
                correctlyAttributedProjReviewers[rank - 1] = { ...prwm };
                return;
              }
              if (rank === 100) {
                correctlyAttributedProjReviewers[correctlyAttributedProjReviewers.length - 1] = { ...prwm };
                return;
              }

              if (rank === 4 && nb_out_rev < 2) {
                correctlyAttributedProjReviewers[rank - 1 + nb_out_rev] = { ...prwm };
                nb_out_rev += 1;
              }
            });

            // const projReviewers = projReviewersWithEmail.map((pr) => pr.uid).slice(0, maxNumberOfReviewers);
            const projReviewers = correctlyAttributedProjReviewers.map((pr) => pr.uid).slice(0, maxNumberOfReviewers);

            // add projct reviewers
            if (projReviewers) {
              outputRow.push(projReviewers.map((revUid, revIdx) => (revUid !== 'fakeUid' ? `${userEmails[revUid]} [${get_rev_short(revIdx + 1)}]` : '')).join('\n'));
            } else {
              outputRow.push('');
            }

            // add administrative review
            outputRow.push('');
            if (administrativeReviews) {
              const adminReview = administrativeReviews[pId] || {};
              if ('result' in adminReview) {
                // let statusText = '';

                // switch (adminReview.result) {
                //   case -1:
                //     statusText = 'rejected';
                //     break;
                //   case 0:
                //     statusText = 'warning';
                //     break;
                //   case 1:
                //     statusText = 'accepted';
                //     break;
                //   default:
                //     statusText = 'unknown';
                // }
                const statusText = adminReview.result;
                outputRow.push(statusText);

                outputRow.push(adminReview.message ?? '');
              } else {
                outputRow.push('');
                outputRow.push('');
              }
            } else {
              outputRow.push('');
              outputRow.push('');
            }
            outputRow.push('');

            // add evaluation globale
            const evalGlobalProject = evaluationsGlobales[pId] || {};
            evaluationQuestions.forEach((q) => {
              outputRow.push(evalGlobalProject[q.name] || '');
              outputRow.push(evalGlobalProject[`${q.name}_justification`] || '');
              outputRow.push(evalGlobalProject[`${q.name}_recommandation`] || '');
            });

            // add project reviews per reviewer and mean
            outputRow.push('');
            if (projReviewers) {
              let nb_rev = 0;
              projReviewers.forEach((revUid) => {
                nb_rev += 1;
                if (all_evaluations[revUid] && all_evaluations[revUid][pId]) {
                  const curEval = all_evaluations[revUid][pId];
                  meanEvalutionScore.forEach((mes, mesIdx) => {
                    if (mes.name in curEval) {
                      if (curEval[mes.name] > 0) {
                        meanEvalutionScore[mesIdx].total += curEval[mes.name];
                        meanEvalutionScore[mesIdx].nb += 1;
                      }

                      meanEvalutionScore[mesIdx].values.push(curEval[mes.name]);
                      meanEvalutionScore[mesIdx].values.push(curEval[`${mes.name}_justification`]);
                      meanEvalutionScore[mesIdx].values.push(curEval[`${mes.name}_recommandation`]);
                    } else {
                      meanEvalutionScore[mesIdx].values.push('n/a');
                      meanEvalutionScore[mesIdx].values.push('n/a');
                      meanEvalutionScore[mesIdx].values.push('n/a');
                    }
                  });
                } else {
                  if (revUid !== 'fakeUid') {
                    meanEvalutionScore.forEach((mes, mesIdx) => {
                      meanEvalutionScore[mesIdx].values.push('n/a');
                      meanEvalutionScore[mesIdx].values.push('n/a');
                      meanEvalutionScore[mesIdx].values.push('n/a');
                    });
                  } else {
                    meanEvalutionScore.forEach((mes, mesIdx) => {
                      meanEvalutionScore[mesIdx].values.push('');
                      meanEvalutionScore[mesIdx].values.push('');
                      meanEvalutionScore[mesIdx].values.push('');
                    });
                  }
                }
              });

              for (let i = nb_rev + 1; i <= maxNumberOfReviewers; i++) {
                meanEvalutionScore.forEach((mes, mesIdx) => {
                  meanEvalutionScore[mesIdx].values.push('');
                  meanEvalutionScore[mesIdx].values.push('');
                  meanEvalutionScore[mesIdx].values.push('');
                });
              }
            }
            // else {
            //   outputRow.push('');
            // }

            // Add mean value in excel
            meanEvalutionScore.forEach((mes) => {
              if (mes.nb > 0) {
                outputRow.push(mes.total / mes.nb);
              } else {
                outputRow.push('');
              }
            });
            outputRow.push('');

            // Add reviewers scores and message
            meanEvalutionScore.forEach((mes) => {
              mes.values.forEach((v) => outputRow.push(v));
              outputRow.push('');
            });
          }
        }

        ws_data.push(outputRow);
      }
    });

    const sorted_ws_data = [
      ws_data[0],

      ...ws_data.slice(1).sort((w1, w2) => {
        if (w1[0] < w2[0]) return -1;
        if (w1[0] > w2[0]) return 1;
        return 0;
      }),
    ];

    var ws = XLSX.utils.aoa_to_sheet(sorted_ws_data);
    wb.Sheets[sheetname] = ws;

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), outputFilename);

    resolve();
  });
};

export const downloadAllProjects = async (userList, userProjects) => {
  //   setExportingFile(true);
  // getEvaluationOfReviewers()
  return await new Promise((resolve, reject) => {
    const columns = [{ title: 'Id' }, { title: 'Submitted' }, { title: 'User' }];

    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: 'Projects List',
      Subject: 'Projects List',
      Author: 'Arnaud Browet',
      CreatedDate: new Date(),
    };

    wb.SheetNames.push('Liste des projets');
    wb.SheetNames.push('Liste des utilisateurs');
    wb.SheetNames.push('Suggestions');

    // set the header
    const ws_data = [columns.map((c) => c.title)];
    const ws_users = [['UserId', 'Email', 'User started a project?']];
    const ws_suggestions = [['Suggestions']];

    const userEmails = userList.reduce((prev, cur) => {
      return { ...prev, [cur.uid]: cur.email };
    }, {});
    const userStartedProject = [];

    // iterate over projects
    Object.keys(userProjects).forEach((pId) => {
      const project_data = userProjects[pId];
      const outputRow = [pId, project_data.status.submitted ? 1 : 0, userEmails[project_data.status.uid]];

      userStartedProject.push(project_data.status.uid);
      ws_data.push(outputRow);

      if (project_data.data.suggestions) {
        ws_suggestions.push([project_data.data.suggestions]);
      }
    });

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets['Liste des projets'] = ws;

    // iterate on users
    Object.keys(userEmails).forEach((userid) => {
      ws_users.push([userid, userEmails[userid], userStartedProject.includes(userid) ? 1 : 0]);
    });

    wb.Sheets['Liste des utilisateurs'] = XLSX.utils.aoa_to_sheet(ws_users);
    wb.Sheets['Suggestions'] = XLSX.utils.aoa_to_sheet(ws_suggestions);

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'projects_list.xlsx');

    resolve();
  });
  //   setExportingFile(false);
};
