/* routes */
export const HOME = '/';
export const LOGIN = '/';
export const ADMIN = '/dashboard';

/* ADMIN */
export const ADMINISTRATIVE_REVIEW = '/review';

/* REVIEWERS */
export const EVALUATION = '/evaluation';

/* MASTER */
export const MANAGE_USERS = '/manage_users';
export const MANAGE_THEMES = '/manage_themes';

/* THEME ADMIN */
export const MANAGE_REVIEWERS = '/manage_reviewers';
export const GLOBAL_EVALUATION = '/global_evaluation';

/* HELPDESK */
export const HELPDESK = '/helpdesk';

export const userApp = 'https://www.mycirculareconomy.brussels/';
