import { useState, useContext } from 'react';
import { FirebaseContext, FirebaseDataContext } from '../../../firebase';

/* MATERIAL */
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import CircularProgress from '@mui/material/CircularProgress';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { APP_YEAR } from "../../../constants";

const filesAccorionStyles = makeStyles((theme) => ({
  projectFilesAccordion: {
    marginTop: 20,
    marginBottom: 20,
  },
  projectFilesAccordionSummary: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '& .MuiAccordionSummary-expandIconWrapper': {
      color: 'white',
    },
  },
  projectFilesAccordionDetails: {
    flexDirection: 'column',
  },
  projectFilesAccordionDetailsBox: {
    paddingTop: 10,
    paddingBottom: 10,
  },
}));

export const FilesAccordion = (props) => {
  /* PROPS VARIABLES */
  const { projectId } = props;

  /* CONTEXTS */
  const firebaseDataContext = useContext(FirebaseDataContext);
  const firebaseContext = useContext(FirebaseContext);

  /* STATES */
  const [expanded, setExpanded] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState({});

  /* INTERNAL VARIABLES */
  const projectData = (firebaseDataContext.userProjects && firebaseDataContext.userProjects[projectId]) || { status: {}, data: {} };
  const aapId = projectData.status.aap_id || null;
  const classes = filesAccorionStyles();

  /* HANDLERS */
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const downloadFile = (fileref, fileid) => {
    setDownloadingFile((prevDown) => ({
      ...prevDown,
      [fileid]: true,
    }));

    const downloadFilename = `ASK_${APP_YEAR}_${fileid}_${projectId}`;
    //${firebaseDataContext.definitions[aapId].name_fr.replace(' ', '_')}

    firebaseContext.app.downloadProjectFile(fileref, downloadFilename).then(
      () => {
        setDownloadingFile((prevDown) => ({
          ...prevDown,
          [fileid]: false,
        }));
      },
      (error) => {
        console.error('Fetching file url', error);
        setDownloadingFile((prevDown) => ({
          ...prevDown,
          [fileid]: false,
        }));
      }
    );
  };
  const downloadProjectPDF = () => {
    setDownloading(true);

    firebaseContext.app.downloadProjectPDF(projectData.status).then(
      () => {
        setDownloading(false);
      },
      (error) => {
        console.error('While downloading projet pdf', error);
        setDownloading(false);
      }
    );
  };

  const file_questions = firebaseDataContext.definitions[aapId].tabs.filter((t) => t.tab_id === 'tab_annexes')[0].sections[0].questions.filter((q) => q.type === 'file');

  return (
    <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')} className={classes.projectFilesAccordion}>
      <AccordionSummary
        className={classes.projectFilesAccordionSummary}
        expandIcon={<ExpandMoreIcon />}
        // expandIconWrapper={{ style: { color: 'white' } }}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography className={classes.heading}>Télécharger les fichiers du projet</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.projectFilesAccordionDetails}>
        <Box component="div" className={classes.projectFilesAccordionDetailsBox}>
          <Button disabled={downloading} variant="contained" color="primary" onClick={downloadProjectPDF}>
            {!downloading ? 'Télécharger le formulaire de candidature' : <CircularProgress disableShrink thickness={3} size={20} />}
          </Button>
        </Box>

        {file_questions.map((q) => {
          const qFileData = projectData.data[`${q.id}`];
          // const qFileData = null;
          return (
            <Box key={q.id} component="div">
              {qFileData && (
                <div className={classes.projectFilesAccordionDetailsBox}>
                  <Typography>{q.label_fr.replace(/<.+>/g, '')}:</Typography>
                  <Button
                    disabled={downloading}
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      downloadFile(qFileData.fileref, q.id);
                    }}
                  >
                    {!downloadingFile[q.id] ? qFileData.filename : <CircularProgress disableShrink thickness={3} size={20} />}
                  </Button>
                </div>
              )}
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};
