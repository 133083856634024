import { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import TextField from '@mui/material/TextField';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Grid';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import LoopIcon from '@mui/icons-material/Loop';
import CircularProgress from '@mui/material/CircularProgress';

// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('Cupcake', 305, 3.7, 67, 4.3),
//   createData('Donut', 452, 25.0, 51, 4.9),
//   createData('Eclair', 262, 16.0, 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const userClaims = ['master', 'admin', 'themeAdmin', 'reviewer', 'helpdesk'];

const headCells = [
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'uid', numeric: false, disablePadding: true, label: 'Uid' },
  { id: 'emailVerified', boolean: true, numeric: false, disablePadding: false, label: 'Email vérifié' },
  { id: 'master', boolean: true, numeric: false, disablePadding: false, label: 'Master' },
  { id: 'admin', boolean: true, numeric: false, disablePadding: false, label: 'Admin' },
  { id: 'themeAdmin', boolean: true, numeric: false, disablePadding: false, label: 'ThemeAdmin' },
  { id: 'reviewer', boolean: true, numeric: false, disablePadding: false, label: 'Reviewer' },
  { id: 'helpdesk', boolean: true, numeric: false, disablePadding: false, label: 'Helpdesk' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {false && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : headCell.boolean ? 'center' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? <span className={classes.visuallyHidden}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</span> : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: theme.palette.secondary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  filterBox: {
    padding: '5 20',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, activeFilters, handleActiveFilters } = props;

  const [showFilters, setShowFilters] = useState(false);

  const handleFilters = () => {
    setShowFilters(!showFilters);
  };

  const correctFilters = ({ target }) => {
    handleActiveFilters({
      target: { name: target.name, value: target.checked },
    });
  };

  return (
    <>
      <Toolbar
        wrap="wrap"
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
            Utilisateurs
          </Typography>
        )}

        {numSelected > 0 ? (
          // <Tooltip title="Delete">
          //   <IconButton aria-label="delete">
          //     <DeleteIcon />
          //   </IconButton>
          // </Tooltip>
          <></>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list" onClick={handleFilters}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
      {showFilters && (
        <Container component="div" maxWidth={false} className={classes.filterBox}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={2}>
              <em>Filtres:</em>
            </Grid>
            <Grid item xs={7}>
              <Box component="div">
                <TextField fullWidth id="filter-email" name="email" label="Email" type="email" onChange={handleActiveFilters} />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <FormGroup>
                {/* checked={state.gilad} onChange={handleChange}  */}
                {userClaims.map((uc) => {
                  return <FormControlLabel key={uc} control={<Switch checked={activeFilters[uc]} onChange={correctFilters} name={uc} />} label={uc} />;
                })}
              </FormGroup>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  pageTitle: {
    fontSize: '1.6rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
    marginBottom: 30,
  },
  userNumbers: {
    marginBottom: 20,
  },
  userStatusNumber: {
    padding: '5px 0',
  },
}));

export const UserTable = (props) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [activeFilters, setActiveFilters] = useState({
    email: '',
    master: false,
    admin: false,
    themeAdmin: false,
    reviewer: false,
    helpdesk: false,
  });

  const inputRows = props.userList.map((u) => ({
    uid: u.uid,
    email: u.email,
    emailVerified: u.emailVerified || false,
    master: (u.customClaims && u.customClaims.master) || false,
    admin: (u.customClaims && u.customClaims.admin) || false,
    themeAdmin: (u.customClaims && u.customClaims.themeAdmin) || false,
    reviewer: (u.customClaims && u.customClaims.reviewer) || false,
    helpdesk: (u.customClaims && u.customClaims.helpdesk) || false,
  }));

  const handleRequestSort = (event, property) => {
    // console.log('request sorting', property);
    // return;

    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);

    const newSelected = selectedIndex < 0 ? [name] : [];

    // let newSelected = [];

    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, name);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleActiveFilters = ({ target }) => {
    const { name, value } = target;

    setSelected([]);
    setActiveFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const rows = inputRows.filter((r) => {
    const valideEmail = activeFilters.email ? r.email.includes(activeFilters.email) : true;
    const valideMaster = activeFilters.master ? r.master : true;
    const valideAdmin = activeFilters.admin ? r.admin : true;
    const valideThemeAdmin = activeFilters.themeAdmin ? r.themeAdmin : true;
    const valideReviewer = activeFilters.reviewer ? r.reviewer : true;
    const valideHelpdesk = activeFilters.helpdesk ? r.helpdesk : true;

    const isCurrentlySelected = selected.indexOf(r.uid) >= 0;
    return (valideEmail && valideMaster && valideAdmin && valideThemeAdmin && valideReviewer && valideHelpdesk) || isCurrentlySelected;
  });

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  //   const nbUsersPerClaim = userClaims.reduce((o, key) => ({ ...o, [key]: 0}), {})
  const nbUsersPerClaim = props.userList.reduce(
    (o, k) => {
      if (k.customClaims) {
        Object.keys(k.customClaims).forEach((claim) => {
          if (userClaims.indexOf(claim) >= 0) {
            o[claim] = (o[claim] || 0) + (k.customClaims[claim] ? 1 : 0);
          }
        });
      }
      return o;
    },
    userClaims.reduce((o, key) => ({ ...o, [key]: 0 }), {})
  );

  const displayRows = stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography className={classes.pageTitle} variant="h1" component="div">
            Liste de utilisateurs
          </Typography>
        </Box>
        <Box>
          <Button variant="contained" color="primary" className={classes.button} startIcon={<LoopIcon />} onClick={props.readUsersList}>
            Rafraichir
          </Button>
        </Box>
      </Box>

      <Box component="div" className={classes.userNumbers}>
        <Box className={classes.userStatusNumber} component="div">
          Utilisateurs enregistrés : {props.userList.length}
        </Box>

        {Object.keys(nbUsersPerClaim).map((claim) => (
          <Box key={claim} className={classes.userStatusNumber} component="div">
            Utilisateurs avec le statut <em>{claim}</em> : {nbUsersPerClaim[claim]}
          </Box>
        ))}
      </Box>

      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={0} activeFilters={activeFilters} handleActiveFilters={handleActiveFilters} />
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size="medium" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {displayRows.map((row, index) => {
                const isItemSelected = isSelected(row.uid);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow hover onClick={(event) => handleClick(event, row.uid)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.uid} selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.email}
                    </TableCell>
                    <TableCell align="left">{row.uid}</TableCell>
                    <TableCell align="center">{row.emailVerified && <IsSet />}</TableCell>

                    {userClaims.map((uc) => {
                      return (
                        <TableCell key={uc} align="center">
                          {row[uc] && <IsSet />}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
              {false && emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Lignes par page:"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {selected.length > 0 && (
        <Box component="div" className={classes.setUserClaim}>
          <ChangeUserClaim selected={selected} rows={rows} editingUserClaim={props.editingUserClaim} editUserClaim={props.editUserClaim} />
        </Box>
      )}
    </Container>
  );
};

const IsSet = () => {
  return (
    <div>
      <CheckCircleIcon color="secondary" />
    </div>
  );
};

const changeUserClaimStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
  },
  userEmail: {
    fontStyle: 'italic',
    fontWeight: 500,
    marginTop: 10,
    paddingLeft: 15,
  },
  permissionsContainer: {
    position: 'relative',
    borderRadius: 5,
    overflow: 'hidden',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    color: '#ccc',
    // backgroundColor: '#3a3838a1',
    backgroundColor: '#ffffffAA',
    display: 'none',
  },
  showBackdrop: {
    display: 'block',
  },
}));

const ChangeUserClaim = (props) => {
  const classes = changeUserClaimStyles();

  const selectedUid = props.selected[0];
  const user = props.rows.filter((r) => r.uid === selectedUid)[0];

  const changeClaim = ({ target }) => {
    props.editUserClaim({ uid: selectedUid, claim: target.name, value: target.checked });
  };

  return (
    <Paper>
      <Container component="div" maxWidth={false} className={classes.container}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={5}>
            <Box>Utilisateur sélectionné:</Box>
            <Box className={classes.userEmail}>{user.email}</Box>
          </Grid>
          <Grid className={classes.permissionsContainer} item xs={7}>
            <Box>Changer les permissions</Box>
            <Box>
              <FormGroup>
                {userClaims.map((uc) => {
                  if (uc === 'master') return <div key={uc}></div>;
                  return <FormControlLabel key={uc} control={<Switch checked={user[uc]} onChange={changeClaim} name={uc} />} label={uc} />;
                })}
                {/* <FormControlLabel control={<Switch checked={user.admin} onChange={changeClaim} name="admin" />} label="Admin" />
                <FormControlLabel control={<Switch checked={user.themeAdmin} onChange={changeClaim} name="themeAdmin" />} label="ThemeAdmin" />
                <FormControlLabel control={<Switch checked={user.reviewer} onChange={changeClaim} name="reviewer" />} label="Reviewer" /> */}
              </FormGroup>
            </Box>
            <Container component="div" maxWidth={false} className={clsx(classes.backdrop, { [classes.showBackdrop]: props.editingUserClaim })}>
              <Grid style={{ height: '100%' }} container direction="column" justifyContent="center" alignItems="center">
                <CircularProgress color="inherit" />
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </Paper>
  );
};
