import { useState, useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { FirebaseContext, FirebaseDataContext, ReviewersListContext } from '../../../../firebase';
import { ADMIN, GLOBAL_EVALUATION } from '../../../../routing/routes';

import { LoadingGrid } from '../../utils';

// import { ReviewerInstructions } from '../../reviewerList/instructions';

import clsx from 'clsx';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoopIcon from '@mui/icons-material/Loop';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HowToRegIcon from '@mui/icons-material/HowToReg';

import { colorStyles } from '../../utils';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  projectLink: {
    margin: '5px 0',
    border: '1px solid #ccc',
    padding: '5px 10px',
    borderRadius: 5,
    display: 'block',
    color: '#333',
    fontWeight: 500,

    '&.accepted': {
      // color: 'white',
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
      borderStyle: 'solid',
    },
    '&.rejected': {
      // color: 'white',
      borderColor: theme.palette.error.main,
      borderWidth: 2,
      borderStyle: 'solid',
    },
    '&.pending': {
      color: 'white',
      backgroundColor: theme.palette.info.main,
    },
    '&.warning': {
      color: 'white',
      backgroundColor: theme.palette.warning.main,
    },
    '&.rlink.pending': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    '&.rlink.onReview': {
      //   color: 'white',
      //   backgroundColor: '#eede47',
      borderColor: theme.palette.primary.main,
      borderWidth: 2,
      borderStyle: 'solid',
    },

    '&:hover': {
      // color:'white',
      color: '#333!important',
      backgroundColor: '#eee!important',
    },
  },
  linkContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    // width: '21%',
    // marginRight: '3%',
    // [theme.breakpoints.down('sm')]: {
    //   width: '90%',
    //   marginRight: '5%',
    // },
  },
  reviewStatus: {
    '& .review-state': {
      display: 'inline-block',
    },
  },
  scoreContainer: {
    padding: 5,
    // background: 'white',
    color: 'white',
    borderRadius: 3,
    fontSize: '.8rem',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  projectName: {
    display: 'inline-block',
    marginRight: 15,
  },
}));

export const GlobalEvaluation = () => {
  const colors = colorStyles();
  const classes = useStyles();

  const firebaseContext = useContext(FirebaseContext);
  const firebaseDataContext = useContext(FirebaseDataContext);
  const reviewersListContext = useContext(ReviewersListContext);

  const myUid = firebaseContext.user.uid;
  const [themes, setThemes] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState('');

  const myThemes = useMemo(() => {
    return themes.filter((theme) => theme.themeAdmin.indexOf(myUid) >= 0);
  }, [themes, myUid]);

  const currentTheme = useMemo(() => {
    return myThemes.filter((theme) => theme.name === selectedTheme)[0];
  }, [myThemes, selectedTheme]);

  useEffect(() => {
    const receivedThemes = firebaseDataContext.themes || [];
    setThemes(receivedThemes);

    // update selected theme after refresh
    const myFetchedThemes = receivedThemes.filter((theme) => theme.themeAdmin.indexOf(myUid) >= 0);

    setSelectedTheme((currentSelectedTheme) => {
      if (currentSelectedTheme === '' && myFetchedThemes.length > 0) {
        return myFetchedThemes[0].name;
      }
      else if (currentSelectedTheme !== '') {
        const goodThemes = myFetchedThemes.filter((theme) => theme.name === currentSelectedTheme);
        if (goodThemes.length === 0) {
          return myFetchedThemes.length ? myFetchedThemes[0].name : '';
        }
      }
      return currentSelectedTheme
    })


    // if (selectedTheme === '' && myFetchedThemes.length > 0) {
    //   setSelectedTheme(myFetchedThemes[0].name);
    // }
    // if (selectedTheme !== '') {
    //   const goodThemes = myFetchedThemes.filter((theme) => theme.name === selectedTheme);
    //   if (goodThemes.length === 0) {
    //     setSelectedTheme(myFetchedThemes.length ? myFetchedThemes[0].name : '');
    //   }
    // }


  }, [firebaseDataContext.themes, myUid]);

  const handleSelectedThemeChange = ({ target }) => {
    setSelectedTheme(target.value);
  };

  if (
    !firebaseDataContext.themesRead ||
    !firebaseDataContext.evaluationsGlobalesRead ||
    !firebaseDataContext.projectsRead ||
    reviewersListContext.readingReviewers ||
    !firebaseDataContext.evaluation_flagRead
  ) {
    return <LoadingGrid />;
  }

  const evaluation_flag = firebaseDataContext.evaluation_flag;
  const evaluationDefinitions = (firebaseDataContext.evaluationDefinitions[evaluation_flag] || {});
  const evaluationQuestions = evaluationDefinitions.evaluationQuestions || [];
  const evaluationParameters = evaluationDefinitions.evaluationParameters || {};

  const administrativeReviews = firebaseDataContext.administrativeReviews || {};

  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      <Box display="flex" alignItems="flex-start">
        <Box flexGrow={1}>
          <Typography variant="h6" sx={{ mb: 2 }} color="primary" component="div">
            Évaluation globale des projets
          </Typography>
        </Box>

        <Box>
          <Button variant="contained" color="primary" startIcon={<LoopIcon />} onClick={reviewersListContext.readReviewersList}>
            Rafraichir
          </Button>
        </Box>
      </Box>

      {/* <Accordion sx={{ mt: 0, p: '10px', mb: '30px' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <u>
            <strong>Instructions pour votre évaluation des dossiers de candidature BeCircular :</strong>
          </u>
        </AccordionSummary>
        <AccordionDetails>
          <ReviewerInstructions />
        </AccordionDetails>
      </Accordion> */}

      <Box sx={{ border: '1px solid #F44436' }} px={1} py={2} mb={2}>

        <Typography fontWeight={700} color={'error'}>
          ATTENTION : Ce module est destiné uniquement aux coordinateurs thématiques pour opérer la synthèse des évaluations de tous les experts après la tenue des jurys. Si un coordinateur thématique est également expert reviewer pour un dossier de candidature, il doit évaluer ce dossier dans le module « Evaluation » (jamais dans le module évaluation globale !!!)
        </Typography>

      </Box>

      {myThemes.length <= 0 && (
        <Paper elevation={2} sx={{ p: '10px', mb: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box>Aucune thématique ne vous a été attribuée pour le moment</Box>
            </Grid>
          </Grid>
        </Paper>
      )}
      {myThemes.length > 0 && (
        <Paper sx={{ p: '10px', mb: '30px' }} elevation={2}>
          <Grid container spacing={2} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <p>Thématique séléctionnée</p>
              <FormControl variant="outlined" fullWidth sx={{ maxWidth: 'min(500px, calc(100% - 48px))' }}>
                <InputLabel id="select-outlined-label">Sélectionnez une thématique</InputLabel>
                <Select labelId="select-outlined-label" id="select-outlined" value={selectedTheme} onChange={handleSelectedThemeChange} label="Sélectionnez une thématique">
                  {myThemes.map((theme) => (
                    <MenuItem key={theme.name} value={theme.name}>
                      {theme.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      )}
      {selectedTheme !== '' && (
        <Paper sx={{ p: '10px', mb: '30px' }} elevation={2}>
          <Grid container spacing={2}>
            {currentTheme.projects.length === 0 && (
              <Grid item xs={12}>
                <Box>Cette thématique ne contient aucun projet pour le moment.</Box>
              </Grid>
            )}
            {currentTheme.projects.length > 0 &&
              currentTheme.projects
                .filter((project) => {
                  if (
                    !(project in firebaseDataContext.userProjects) ||
                    !firebaseDataContext.userProjects[project].status.submitted
                  ) return false;

                  if (evaluationParameters.validAdministrativeResult) {
                    if (
                      !(project in administrativeReviews) ||
                      (administrativeReviews[project].flag !== evaluation_flag) ||
                      (administrativeReviews[project].status !== 'reviewed') ||
                      !evaluationParameters.validAdministrativeResult.includes(administrativeReviews[project].result)
                    ) {
                      return false;
                    }
                  }


                  return true;
                })
                .map((p) => {
                  const evaluationData = firebaseDataContext.evaluationsGlobales[p] || {};
                  const projectData = firebaseDataContext.userProjects[p] || { data: {}, status: {} };

                  const averageScoreMap = evaluationQuestions.reduce(
                    (prev, q) => {
                      if (q.conditional) {
                        const field = q.conditional.field || '';
                        const value = q.conditional.value || [];
                        const projectEntry = q.conditional.status_field ? projectData.status : projectData.data;
                        if (!value.includes(projectEntry[field])) {
                          return prev;
                        }
                      }

                      if (q.name in evaluationData && q.eval_type === 'cote') {
                        if (evaluationData[q.name] > 0) {
                          prev.n += 1;
                          prev.s += evaluationData[q.name];
                        }
                      }
                      return prev;
                    },
                    { n: 0, s: 0 }
                  );
                  let score = -1;
                  if (averageScoreMap.n > 0) {
                    score = averageScoreMap.s / averageScoreMap.n;
                    score = Math.round(100 * score) / 100;
                  }
                  return { project: p, score: score };
                })
                .sort((proj1, proj2) => {
                  const { project: p1, score: s1 } = proj1;
                  const { project: p2, score: s2 } = proj2;

                  const p1Data = firebaseDataContext.evaluationsGlobales[p1] || [];
                  const p2Data = firebaseDataContext.evaluationsGlobales[p2] || [];

                  const p1Reviewed = 'status' in p1Data && evaluation_flag in p1Data.status;
                  const p2Reviewed = 'status' in p2Data && evaluation_flag in p2Data.status;

                  const p1n = ((firebaseDataContext.userProjects[p1] || {}).status || {}).project_number || 0;
                  const p2n = ((firebaseDataContext.userProjects[p2] || {}).status || {}).project_number || 0;

                  if (p1Reviewed && p2Reviewed) {
                    return s1 > s2 ? -1 : s1 < s2 ? 1 : 0;
                    // return 0;
                  }
                  if (!p1Reviewed && !p2Reviewed) {
                    return p1n < p2n ? -1 : p1n > p2n ? 1 : 0;
                    // return 0;
                  }
                  if (p1Reviewed && !p2Reviewed) return 1;
                  if (!p1Reviewed && p2Reviewed) return -1;

                  return 0;
                })
                .map((pp) => {
                  const { project, score } = pp;
                  const projectData = firebaseDataContext.userProjects[project];
                  const projectNumber = projectData.status.project_number || 0;

                  const evaluationData = firebaseDataContext.evaluationsGlobales[project] || [];
                  const reviewExist = Object.keys(evaluationData).length > 0;
                  const reviewFinished = reviewExist && 'status' in evaluationData && evaluation_flag in evaluationData.status;

                  const isOnReview = reviewExist && !reviewFinished;
                  const isAccepted = reviewFinished && evaluationData.status[evaluation_flag] === 'accepted';
                  const isRejected = reviewFinished && evaluationData.status[evaluation_flag] === 'rejected';
                  console.log('global pp', pp)

                  const projectReviewers = currentTheme.reviewers[project] || [];
                  return (
                    <Grid item key={project} xs={12} sm={6} md={6} lg={4}>
                      <Link to={`${ADMIN}${GLOBAL_EVALUATION}/${project}`} state={{ themeName: currentTheme.name }}>
                        <Box className={classes.linkContainer}>
                          <div className={`${classes.projectLink} rlink ${isOnReview ? 'onReview' : ''} ${isAccepted ? 'accepted' : ''} ${isRejected ? 'rejected' : ''}`}>
                            <div className={clsx(classes.projectName)}>
                              {projectNumber ? `#${projectNumber} - ` : ''}
                              {projectData.data.acronyme || projectData.data.identity || '???'}
                            </div>
                            {score !== -1 && (
                              <div
                                className={clsx(classes.scoreContainer, {
                                  [colors.bckgGreen]: score <= 20,
                                  [colors.bckgBlue]: score <= 15,
                                  [colors.bckgOrange]: score <= 10,
                                  [colors.bckgRed]: score <= 5,
                                })}
                              >
                                {score}
                              </div>
                            )}
                            <Box className={classes.reviewStatus} sx={{ pt: 1 }}>
                              {projectReviewers.map((rev) => {
                                const loading = !(rev in reviewersListContext.reviewersEvaluations);
                                const reviewNotStarted = rev in reviewersListContext.reviewersEvaluations && !(project in reviewersListContext.reviewersEvaluations[rev]);

                                const inReview = !loading && !reviewNotStarted && !reviewersListContext.reviewersEvaluations[rev][project][evaluation_flag];
                                const reviewed = !loading && !reviewNotStarted && reviewersListContext.reviewersEvaluations[rev][project][evaluation_flag];

                                return (
                                  <Box key={rev} className="review-state" sx={{ pr: 1 }}>
                                    {loading && <LoopIcon />}
                                    {reviewNotStarted && <NoAccountsIcon color="warning" />}
                                    {inReview && <ManageAccountsIcon color="primary" />}
                                    {reviewed && <HowToRegIcon color="secondary" />}
                                  </Box>
                                );
                              })}
                            </Box>
                          </div>
                        </Box>
                      </Link>
                    </Grid>
                  );
                })}
          </Grid>
        </Paper>
      )}
    </Container>
  );
};
